// @TODO: Remove onClick prop after we develop a complete solution for GTM
// @NOTE: onClick prop should be used ony for GTM events!!!

import { useMemo } from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'

const getHreflang = href => {
    // matches starting slash or .hr in url
    if (href.match(/(^\/)|(\.hr)/)) {
        return 'hr'
    }

    return null
}

// @NOTE: Sometimes Link component does not need 'a' tag in it because the component will handle the tag (see Button)
function HmnLink({ children, href, as, passHref, scroll, noTag, title, isExternal, className, id, onClick }) {
    const hrefLang = useMemo(() => {
        if (!href) {
            return undefined
        }

        return getHreflang(href)
    }, [href])

    if (!href || !children) {
        return null
    }

    if (href === '#') {
        return (
            <a href={href} hrefLang={hrefLang} title={title} aria-label={title} className={className} onClick={onClick}>
                {children}
            </a>
        )
    }

    return isExternal || !!href.match(/http(s):\/\//gim) ? (
        // @NOTE: client requested norefferer removal
        // eslint-disable-next-line react/jsx-no-target-blank
        <a
            href={href}
            title={title}
            aria-label={title}
            className={className}
            rel="noopener"
            target="_blank"
            hrefLang={hrefLang}
            onClick={onClick}>
            {children}
        </a>
    ) : (
        <Link
            href={href}
            as={as}
            hrefLang={hrefLang}
            passHref={noTag ? passHref : false}
            scroll={scroll}
            legacyBehavior>
            {!noTag ? (
                // eslint-disable-next-line
                <a className={className} id={id} hrefLang={hrefLang} title={title} aria-label={title} onClick={onClick} data-upscore-url="">
                    {children}
                </a>
            ) : (
                children
            )}
        </Link>
    )
}

HmnLink.propTypes = {
    href: PropTypes.string,
    as: PropTypes.string,
    passHref: PropTypes.bool,
    scroll: PropTypes.bool,
    title: PropTypes.string,
    noTag: PropTypes.bool,
    isExternal: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string
}

HmnLink.defaultProps = {
    href: undefined,
    as: undefined,
    passHref: false,
    scroll: true,
    title: undefined,
    noTag: false,
    isExternal: false,
    className: undefined,
    onClick: undefined,
    id: undefined
}

export default HmnLink
