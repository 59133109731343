export { default as TaurusIcon } from './Taurus'
export { default as AquariusIcon } from './Aquarius'
export { default as AriesIcon } from './Aries'
export { default as CancerIcon } from './Cancer'
export { default as CapricornIcon } from './Capricorn'
export { default as GeminiIcon } from './Gemini'
export { default as LeoIcon } from './Leo'
export { default as LibraIcon } from './Libra'
export { default as PiscesIcon } from './Pisces'
export { default as SagittariusIcon } from './Sagittarius'
export { default as ScorpioIcon } from './Scorpio'
export { default as VirgoIcon } from './Virgo'
