import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant, sizeVariant }) => {
    const special1x1 = sizeVariant === '1x1'
    const special1x3 = sizeVariant === '1x3'

    const config = {
        content: {
            // background: theme.colors.special.inner.content.background.default
        },
        xs: {
            content: {
                variant: {
                    alpha: {
                        ...(special1x1 && {
                            margin: toRems([0, -15]),
                            padding: toRems([0, 30, 35]),
                            innerWidth: '100%',
                            width: `calc(100% + ${toRem(30)})`,
                            height: toRem(400),
                            fontSize: toRem(24)
                        }),
                        ...(special1x3 && {
                            margin: 0,
                            padding: toRems([0, 30, 35]),
                            innerWidth: '100%',
                            width: '100%',
                            height: toRem(400),
                            fontSize: toRem(24)
                        })
                    },
                    beta: {
                        ...(special1x1 && {
                            margin: 0,
                            padding: toRems([0, 15, 15]),
                            innerWidth: '100%',
                            width: '100%',
                            height: toRem(420),
                            fontSize: toRem(28)
                        }),
                        ...(special1x3 && {
                            margin: toRems([0, -15]),
                            padding: toRems([0, 15, 15]),
                            innerWidth: '100%',
                            width: `calc(100% + ${toRem(30)})`,
                            height: toRem(400),
                            fontSize: toRem(28)
                        })
                    }
                }
            },
            label: {
                fontSize: toRem(18)
            }
        },
        md: {
            content: {
                variant: {
                    alpha: {
                        ...(special1x1 && {
                            margin: toRems([0, -30]),
                            padding: toRems([0, 60, 65]),
                            innerWidth: '60%',
                            width: `calc(100% + ${toRem(60)})`,
                            height: toRem(600),
                            fontSize: toRem(32)
                        }),
                        ...(special1x3 && {
                            margin: 0,
                            padding: toRems([0, 30, 35]),
                            innerWidth: '100%',
                            minWidth: '100%',
                            width: '33%',
                            height: toRem(600),
                            fontSize: toRem(24)
                        })
                    },
                    beta: {
                        ...(special1x1 && {
                            margin: 0,
                            padding: toRems([0, 30, 35]),
                            innerWidth: '50%',
                            width: '100%',
                            height: toRem(600),
                            fontSize: toRem(42)
                        }),
                        ...(special1x3 && {
                            margin: 0,
                            padding: toRems([0, 15, 15]),
                            innerWidth: '70%',
                            width: '100%',
                            height: toRem(600),
                            fontSize: toRem(28)
                        })
                    }
                }
            },
            label: {
                fontSize: toRem(22)
            }
        }
    }

    return {
        overflow: 'hidden',
        lineHeight: 1.2,
        margin: config?.xs.content?.variant?.[variant]?.margin,
        width: config?.xs.content?.variant?.[variant]?.width,
        height: config?.xs.content?.variant?.[variant]?.height,
        fontFamily: theme.typography.fontFamilyAlt,
        [theme.breakpoints.up('md')]: {
            margin: config?.md.content?.variant?.[variant]?.margin,
            height: config?.md.content?.variant?.[variant]?.height,
            minWidth: config?.md.content?.variant?.[variant]?.minWidth,
            width: config?.md.content?.variant?.[variant]?.width
        },
        '& .specialInner': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            border: 'none',
            color: theme.colors.text.default,
            overflow: 'hidden'
        },
        '& .specialImage': {
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            '& > div': {
                height: '100%'
            },
            img: {
                margin: 0,
                objectFit: 'cover'
            }
        },
        '&.is_live .cardContent_live': {
            position: 'absolute',
            top: '16px',
            left: '16px',
            zIndex: '10',
            display: 'block',
            width: '60px',
            height: '24px',
            background: '#DD0000',
            borderRadius: '100px',

            /* UŽIVO */
            fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '11px',
            lineHeight: '25px',
            textAlign: 'center',
            textTransform: 'uppercase',

            color: '#FFFFFF',

            '.dot': {
                display: 'inline-block',
                width: '5px',
                height: '5px',
                margin: '0 4px 1.5px -1px',

                backgroundColor: '#FFFFFF',
                borderRadius: '100%',
                animation: 'pulse 1.3s infinite',

                '@keyframes pulse': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.1 },
                    '100%': { opacity: 1 }
                }
            },

            [theme.breakpoints.up('md')]: {
                top: '31px',
                left: '31px',
                width: '76px',
                height: '28px',
                fontSize: '13px',
                lineHeight: '29px',

                '.dot': {
                    width: '6px',
                    height: '6px',
                    margin: '0 5px 2px -3px'
                }
            }
        },

        '& .specialOverlay': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(78.53deg, #080808 7.05%, rgba(8, 8, 8, 0) 58.33%)'
        },
        '& .specialContent': {
            position: 'absolute',
            left: 0,
            bottom: 0,
            background: config.content.background,
            zIndex: theme.zIndex.over,
            '&-inner': {
                padding: config?.xs.content.variant[variant]?.padding,
                width: config?.xs.content.variant[variant]?.innerWidth,
                [theme.breakpoints.up('md')]: {
                    width: config?.md.content.variant[variant]?.innerWidth,
                    padding: config?.md.content.variant[variant]?.padding,
                    margin: config?.md.content.variant[variant]?.margin
                },
                '& .title, & .title_title': {
                    fontSize: config?.xs.content.variant[variant]?.fontSize,
                    lineHeight: 1.2,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config?.md.content.variant[variant]?.fontSize
                    }
                }
            }
        },
        '& .specialContent_title': {
            marginTop: toRem(15),
            h3: {
                fontFamily: theme.typography.fontFamilyAlt,
                fontWeight: theme.typography.fontWeight.black
            },
            span: {
                color: theme.colors.text.inverted
            }
        },
        '& .specialContent_label': {
            fontWeight: theme.typography.fontWeight.extraBold,
            fontFamily: theme.typography.fontFamilyExtra,
            textTransform: 'uppercase',
            padding: toRems([2, 8]),
            backgroundColor: theme.colors.background.accent,
            color: theme.colors.text.inverted,
            fontSize: config.xs.label.fontSize,
            [theme.breakpoints.up('md')]: {
                fontSize: config.md.label.fontSize
            }
        },
        '& .specialContent_separator': {
            fontFamily: theme.typography.fontFamilyAlt,
            fontWeight: theme.typography.fontWeight.black,
            color: theme.colors.special.label.default,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'color'
        },
        '& .specialContent_lead': {
            display: 'none',
            marginTop: toRem(5),
            color: theme.colors.special.lead.default,
            fontSize: toRem(16),
            fontWeight: theme.typography.fontWeight.semiBold,
            lineHeight: 1.4,
            [theme.breakpoints.up('md')]: {
                display: 'block',
                ...(special1x3 && {
                    display: 'none'
                })
            }
        },
        '& .specialContent_author': {
            height: toRem(110),
            [theme.breakpoints.up('md')]: {
                transition: 'height 0.5s, opacity 0.5s',
                height: 0,
                opacity: 0
            },
            [theme.breakpoints.down('sm')]: {
                img: {
                    maxWidth: toRem(170),
                    objectFit: 'contain'
                }
            },

            '.author_info': {
                marginLeft: toRem(30),
                [theme.breakpoints.up('md')]: {
                    marginLeft: toRem(30)
                }
            },

            'img + .author_info': {
                marginLeft: toRem(10)
            }
        },

        '&:hover': {
            '& .specialContent_author': {
                [theme.breakpoints.up('md')]: {
                    height: toRem(200),
                    opacity: 1,
                    ...(special1x3 && {
                        height: toRem(110)
                    })
                }
            }
        }
    }
}

export default style
