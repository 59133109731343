import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        sm: {
            padding: toRems([0, 16, 16, 16]),
            content: {
                fontSize: toRem(16),
                lineHeight: 1.6
            },
            icon: {
                left: toRem(10),
                top: toRem(-10),
                width: toRem(30),
                height: toRem(30)
            }
        },
        md: {
            padding: toRems([0, 30, 20, 40]),
            content: {
                fontSize: toRem(18),
                lineHeight: 1.7
            },
            icon: {
                left: toRem(-10),
                top: toRem(20),
                width: toRem(36),
                height: toRem(36)
            }
        }
    }

    return {
        position: 'relative',
        margin: toRems([30, -15, 20]),
        backgroundColor: theme.colors.background.light,
        padding: config.sm.padding,
        [theme.breakpoints.up('md')]: {
            margin: toRems([30, 0]),
            padding: config.md.padding
        },
        '.blockquote': {
            '&_icon': {
                backgroundColor: theme.colors.background.accent,
                padding: toRem(5),
                position: 'absolute',
                top: config.sm.icon.top,
                left: config.sm.icon.left,
                width: config.sm.icon.width,
                height: config.sm.icon.height,
                [theme.breakpoints.up('md')]: {
                    padding: toRem(8),
                    top: config.md.icon.top,
                    left: config.md.icon.left,
                    width: config.md.icon.width,
                    height: config.md.icon.height
                }
            },
            '&_content': {
                color: theme.colors.text.placeholder,
                position: 'relative',
                fontStyle: 'italic',
                fontWeight: 400,
                lineHeight: config.sm.content.lineHeight,
                fontSize: config.sm.content.fontSize,
                [theme.breakpoints.up('md')]: {
                    top: toRem(-10),
                    lineHeight: config.md.content.lineHeight,
                    fontSize: config.md.content.fontSize
                }
            }
        }
    }
}

export default style
