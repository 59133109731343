import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Button, netButtonVariants } from '../../../Button'
import { Graphic, netGraphicFillVariants, netGraphicVariants } from '../../../Decoration/components'
import { ArrowNetIcon, Icon } from '../../../Icon'
import styles from './CtaBlock.net.style'

const CtaBlockStyled = styled.div(props => ({ ...styles(props) }))

function CtaBlock({ className, content, title, href, buttonTitle, ...rest }) {
    return (
        <CtaBlockStyled className={className} hasContent={!!content} {...rest}>
            <div className="cta_text">
                <div className="cta_text_title">{title}</div>
                <div className="cta_text_content">{content}</div>
            </div>
            <Button
                className="cta_button"
                variant={netButtonVariants.BETA}
                heightMd={50}
                heightXs={42}
                href={href}
                iconComponent={<Icon className="cta_button_icon" icon={ArrowNetIcon} viewBox="0 0 16 16" />}>
                {buttonTitle || 'Saznaj'}
            </Button>
            <Graphic
                className="cta_decoration"
                variant={netGraphicVariants.ALPHA}
                fillVariant={netGraphicFillVariants.ALTERNATIVE}
                heightMd={10}
                heightXs={10}
                widthMd="100%"
                widthXs="100%"
            />
        </CtaBlockStyled>
    )
}

CtaBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    href: PropTypes.string,
    buttonTitle: PropTypes.string
}

CtaBlock.defaultProps = {
    className: undefined,
    title: undefined,
    content: undefined,
    href: undefined,
    buttonTitle: undefined
}

export default withErrorBoundary(CtaBlock, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[CtaBlock]: ', error, componentStack)
    }
})
