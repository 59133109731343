import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    marginBottom: toRem(20),
    a: {
        fontSize: toRem(14),
        padding: toRems([0, 25]),
        fontWeight: theme.typography.fontWeight.extraBold,
        color: `${theme.colors.text.default} !important`,
        transition: 'color 0.3s ease-in-out',
        textDecoration: 'none',
        '&:hover': {
            color: `${theme.colors.text.accent} !important`
        }
    },
    '& .ReadMore_icon': {
        marginRight: toRem(4)
    },
    [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
    }
})

export default style
