// import { toRem, toRems } from '../../../../helpers/theme'

import { toRem } from '../../../../helpers'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => {
    const config = {
        sm: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            social: {
                marginTop: toRem(18)
            }
        },
        md: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            social: {
                marginTop: 0
            }
        }
    }
    return {
        display: 'flex',
        justifyContent: config.sm.justifyContent,
        flexDirection: config.sm.flexDirection,
        [theme.breakpoints.up('md')]: {
            flexDirection: config.md.flexDirection,
            justifyContent: config.md.justifyContent
        },
        '.meta': {
            '&_social': {
                marginTop: config.sm.social.marginTop,
                [theme.breakpoints.up('md')]: {
                    marginTop: config.md.social.marginTop
                }
            }
        }
    }
}

export default style
