/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Photo = createSvgIcon(
    <path
        d="M39.419 34.831c0 4.091-3.328 7.42-7.419 7.42-4.09 0-7.417-3.329-7.417-7.42 0-4.09 3.326-7.418 7.417-7.418 4.09 0 7.419 3.329 7.419 7.418zM55 24.297v21.071a5.087 5.087 0 01-5.087 5.087H14.087A5.087 5.087 0 019 45.368v-21.07a5.087 5.087 0 015.086-5.087h6.257v-1.76A4.45 4.45 0 0124.793 13h14.413a4.45 4.45 0 014.451 4.45v1.76h6.257A5.089 5.089 0 0155 24.296zM43.234 34.831c0-6.194-5.04-11.233-11.234-11.233-6.193 0-11.232 5.04-11.232 11.233 0 6.194 5.04 11.234 11.232 11.234 6.194 0 11.234-5.04 11.234-11.234z"
        fill="#fff"
    />,
    'Photo'
)

export default Photo
