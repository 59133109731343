import { responsive, toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, sizeVariant, categoryColor, tiny }) => {
    const contentMarker1x1 = sizeVariant === '1x1'
    const contentMarker1x2 = sizeVariant === '1x2'
    const contentMarker1x3 = sizeVariant === '1x3'
    const contentMarker1x4 = sizeVariant === '1x4'
    const contentMarker1x5 = sizeVariant === '1x5'
    const contentMarker1x6 = sizeVariant === '1x6'
    const contentMarker2x3 = sizeVariant === '2x3'

    return {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: categoryColor || theme.colors?.brand,
        color: theme.colors.media?.color,
        opacity: 0.5,
        borderRadius: '20%',
        ...(contentMarker1x1 && {
            ...responsive([
                { width: toRem(60), height: toRem(60), fontSize: toRem(40) },
                { width: toRem(60), height: toRem(60), fontSize: toRem(40) },
                { width: toRem(100), height: toRem(100), fontSize: toRem(70) },
                { width: toRem(100), height: toRem(100), fontSize: toRem(70) },
                { width: toRem(100), height: toRem(100), fontSize: toRem(70) }
            ])
        }),
        ...(contentMarker1x2 && {
            ...responsive([
                { width: toRem(60), height: toRem(60), fontSize: toRem(40) },
                { width: toRem(60), height: toRem(60), fontSize: toRem(40) },
                { width: toRem(80), height: toRem(80), fontSize: toRem(50) },
                { width: toRem(80), height: toRem(80), fontSize: toRem(50) },
                { width: toRem(80), height: toRem(80), fontSize: toRem(50) }
            ])
        }),
        ...((contentMarker1x3 || contentMarker2x3) && {
            ...responsive([
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(30) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(30) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(30) }
            ])
        }),
        ...(contentMarker1x4 && {
            ...responsive([
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) }
            ])
        }),
        ...(contentMarker1x5 && {
            ...responsive([
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) },
                { width: toRem(50), height: toRem(50), fontSize: toRem(34) }
            ])
        }),
        ...(contentMarker1x6 && {
            ...responsive([
                { width: toRem(32), height: toRem(32), fontSize: toRem(25) },
                { width: toRem(32), height: toRem(32), fontSize: toRem(25) },
                { width: toRem(32), height: toRem(36), fontSize: toRem(25) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) }
            ])
        }),
        ...(tiny && {
            ...responsive([
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) },
                { width: toRem(40), height: toRem(40), fontSize: toRem(28) }
            ])
        }),
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
