import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import styles from './Graphic.style'

const GraphicStyled = styled.div(props => ({ ...styles(props) }))

function Graphic({ className, widthXs, heightXs, widthMd, heightMd, variant, fillVariant, ...rest }) {
    return (
        <GraphicStyled
            className={className}
            variant={variant}
            fillVariant={fillVariant}
            widthXs={widthXs}
            heightXs={heightXs}
            widthMd={widthMd}
            heightMd={heightMd}
            {...rest}
        />
    )
}

const netGraphicVariants = Object.freeze({
    ALPHA: 'alpha'
})

const netGraphicFillVariants = Object.freeze({
    DEFAULT: 'default',
    ALTERNATIVE: 'alternative'
})

Graphic.propTypes = {
    className: PropTypes.string,
    widthXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    widthMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heightXs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    heightMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variant: PropTypes.oneOf([...Object.values(netGraphicVariants)]),
    fillVariant: PropTypes.oneOf([...Object.values(netGraphicFillVariants)])
}

Graphic.defaultProps = {
    className: undefined,
    widthXs: undefined,
    widthMd: undefined,
    heightXs: undefined,
    heightMd: undefined,
    variant: netGraphicVariants.ALPHA,
    fillVariant: netGraphicFillVariants.DEFAULT
}

export { netGraphicVariants, netGraphicFillVariants }

export default withErrorBoundary(Graphic, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Graphic]: ', error, componentStack)
    }
})
