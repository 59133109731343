import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => {
    const config = {
        sm: {
            width: toRem(65),
            height: toRem(110)
        },
        md: {
            width: toRem(130),
            height: toRem(200)
        }
    }
    return {
        display: 'flex',
        height: config.sm.height,
        [theme.breakpoints.up('md')]: {
            height: config.md.height
        },
        '& .author': {
            '&_image': {
                width: config.sm.width,
                height: config.sm.height,
                [theme.breakpoints.up('md')]: {
                    width: config.md.width,
                    height: config.md.height
                },
                '&_progressive': {
                    width: config.sm.width,
                    paddingBottom: config.sm.height,
                    [theme.breakpoints.up('md')]: {
                        width: config.md.width,
                        paddingBottom: config.md.height
                    }
                }
            },
            '&_info': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                fontSize: toRem(18),
                lineHeight: 1.2,
                marginLeft: toRem(10),
                '&_label': {
                    fontWeight: 700,
                    color: theme.colors.text.inverted
                },
                '&_name': {
                    fontWeight: 900,
                    color: theme.colors.text.accent
                }
            }
        }
    }
}

export default style
