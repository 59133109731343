/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ArrowUp = createSvgIcon(
    <path d="M 2.14732 20.57147 L 6.99605 25.42021 L 16.00025 16.416 L 25.00457 25.42021 L 29.85326 20.57147 L 16.00025 6.71853 L 2.14732 20.57147 Z" />,
    'ArrowUp'
)

export default ArrowUp
