// @TODO: revisit props when real data is connected
// @TODO: adjust icon color
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import isNumeric from '../../../../../helpers/utils/isNumeric'
import { Icon, UnionIcon } from '../../../../Icon'
import { Image, imageRatioVariants } from '../../../../Image'
import styles from './Item.style'

const PollItemStyled = styled.div(props => ({ ...styles(props) }))

function PollItem({ title, radioGroup, name, isSelected, image, className, pollVariant, votePercentage, ...rest }) {
    if (!radioGroup && !name) {
        return null
    }

    return (
        <PollItemStyled
            className={className}
            pollVariant={pollVariant}
            votePercentage={votePercentage}
            isSelected={isSelected}
            {...rest}>
            <div className="pollItem">
                {['beta'].includes(pollVariant) && (
                    <Image
                        className="pollItem_image"
                        classNameProgressive="pollItem_image_progressive"
                        variation={imageRatioVariants.CUSTOM_SQUARE}
                        image={image?.id}
                        updatedAt={image?.updated_at}
                    />
                )}
                <div className="pollItem_info">
                    <div className="pollItem_info_head">
                        <div className="pollItem_info_head_title">{title}</div>
                        <div className="pollItem_select">
                            {isNumeric(votePercentage) ? (
                                <span className="pollItem_info_head_vote">{votePercentage}%</span>
                            ) : (
                                <Icon className="pollItem_select_item" icon={UnionIcon} size={18} />
                            )}
                        </div>
                    </div>
                    {!!votePercentage && (
                        <div className="pollItem_info_votes">
                            <div className="pollItem_info_votes_bar" />
                        </div>
                    )}
                </div>
            </div>
        </PollItemStyled>
    )
}

PollItem.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    radioGroup: PropTypes.string,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isSelected: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    pollVariant: PropTypes.string,
    votePercentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

PollItem.defaultProps = {
    className: undefined,
    title: undefined,
    radioGroup: undefined,
    name: undefined,
    isSelected: undefined,
    image: undefined,
    pollVariant: 'alpha',
    votePercentage: undefined
}

export default withErrorBoundary(PollItem, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[PollItem]: ', error, componentStack)
    }
})
