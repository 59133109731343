/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const WhatsAppNet = createSvgIcon(
    <>
        <path
            fill="#25D366"
            className="background"
            d="M0 11.2c0-5.28 0-7.92 1.64-9.56C3.28 0 5.92 0 11.2 0h5.6c5.28 0 7.92 0 9.56 1.64C28 3.28 28 5.92 28 11.2v5.6c0 5.28 0 7.92-1.64 9.56C24.72 28 22.08 28 16.8 28h-5.6c-5.28 0-7.92 0-9.56-1.64C0 24.72 0 22.08 0 16.8v-5.6z"
        />
        <path
            fill="#fff"
            fillRule="evenodd"
            d="M19.669 8.336A7.915 7.915 0 0014.033 6c-4.39 0-7.964 3.574-7.966 7.966 0 1.404.366 2.775 1.063 3.983L6 22.077l4.223-1.108a7.958 7.958 0 003.807.97h.004c4.39 0 7.964-3.574 7.966-7.967 0-2.128-.827-4.13-2.331-5.636zm-5.636 12.257h-.002a6.613 6.613 0 01-3.37-.923l-.242-.143-2.506.657.669-2.443-.158-.25a6.605 6.605 0 01-1.012-3.525 6.631 6.631 0 016.624-6.62c1.769 0 3.431.69 4.681 1.941a6.582 6.582 0 011.938 4.685c-.002 3.65-2.972 6.621-6.622 6.621zm3.633-4.959c-.2-.1-1.178-.58-1.36-.647-.183-.067-.316-.1-.449.1-.132.198-.514.647-.63.78-.116.132-.232.15-.431.05-.2-.1-.84-.31-1.6-.989-.593-.527-.992-1.18-1.108-1.378-.116-.2-.001-.297.087-.407.215-.267.431-.548.498-.68.066-.133.033-.25-.017-.35-.05-.099-.448-1.079-.614-1.477-.161-.388-.325-.336-.448-.342a7.936 7.936 0 00-.381-.007.731.731 0 00-.53.25c-.183.198-.698.68-.698 1.66 0 .98.714 1.926.813 2.059.1.133 1.404 2.143 3.4 3.006.476.205.846.327 1.136.419.476.151.91.13 1.253.079.383-.057 1.178-.482 1.344-.947.166-.465.166-.863.116-.946-.05-.083-.183-.133-.381-.233z"
            clipRule="evenodd"
        />
    </>,
    'WhatsAppNet'
)

export default WhatsAppNet
