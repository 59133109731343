import { toRem, toRems } from '../../../../helpers/theme'

// @TODO: REPLACE WITH NET THEME
const style = ({ theme, variant }) => {
    const config = {
        variant: {
            alpha: {
                position: 'relative',
                padding: toRems([7, 7]),
                left: toRem(-10),
                boxShadow: '4px 5px 4px -3px rgba(8,8,8,0.4)'
            },
            beta: {
                position: 'absolute',
                padding: 0,
                transform: 'rotate(10deg)',
                boxShadow: '0px 5px 4px -3px rgba(8,8,8,0.4)',
                sm: {
                    width: toRem(58),
                    height: toRem(58)
                },
                md: {
                    width: toRem(70),
                    height: toRem(70)
                }
            }
        }
    }

    return {
        position: config.variant[variant]?.position,
        display: 'inline-block',
        left: config.variant[variant]?.left,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.colors.decoration.text,
        backgroundColor: theme.colors.decoration.yellow,
        padding: config.variant[variant].padding,
        transform: config.variant[variant]?.transform,
        fontSize: toRem(16),
        lineHeight: 1.2,
        boxShadow: config.variant[variant]?.boxShadow,
        width: config.variant[variant]?.sm?.width,
        height: config.variant[variant]?.sm?.height,
        [theme.breakpoints.up('md')]: {
            width: config.variant[variant]?.md?.width,
            height: config.variant[variant]?.md?.height
        },
        ...(variant === 'alpha' && {
            '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                width: 0,
                height: 0,
                borderTop: `${toRem(6)} solid ${theme.colors.decoration.before}`,
                borderRight: `${toRem(6)} solid ${theme.colors.decoration.before}`,
                borderLeft: `${toRem(5)} solid transparent`,
                borderBottom: `${toRem(6)} solid transparent`,
                top: '100%',
                left: 0
            }
        }),
        '& .ribbon': {
            '&_icon': {
                width: toRem(48),
                height: toRem(48),
                marginTop: toRem(20),
                marginLeft: toRem(14)
            }
        }
    }
}

export default style
