import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import styles from './ImageWrapper.style'

const ImageWrapperStyled = styled.div(props => ({ ...styles(props) }))

function ImageWrapper({ children, className, width, height, maxWidth, maxHeight }) {
    return (
        <ImageWrapperStyled
            className={className}
            width={width}
            height={height}
            maxWidth={maxWidth}
            maxHeight={maxHeight}>
            {children}
        </ImageWrapperStyled>
    )
}

ImageWrapper.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string
}

ImageWrapper.defaultProps = {
    className: undefined,
    width: undefined,
    height: undefined,
    maxWidth: undefined,
    maxHeight: undefined
}

export default ImageWrapper
