import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, hasContent }) => {
    const config = {
        sm: {
            text: {
                title: {
                    fontSize: toRem(16)
                },
                content: {
                    fontSize: toRem(24)
                }
            },
            button: {
                marginTop: toRem(18)
            }
        },
        md: {
            width: '100%',
            flexDirection: 'row',
            button: {
                marginLeft: '7%',
                marginTop: toRem(10),
                minWidth: toRem(140)
            },
            text: {
                title: {
                    fontSize: toRem(16)
                },
                content: {
                    fontSize: toRem(24)
                }
            }
        }
    }
    return {
        backgroundColor: theme.colors.background.light,
        display: 'flex',
        flexDirection: 'column',
        padding: toRems([25, 20, 25, 20]),
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            flexDirection: config.md.flexDirection || 'column',
            alignItems: 'center',
            padding: toRems([34, 40, 40, 40])
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: toRem(350),
            width: 'calc(100% -30)'
        },
        '& .cta': {
            '&_text': {
                color: theme.colors.text.default,
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    flexDirection: config.md.flexDirection || 'column',
                    width: '85%'
                },
                '&_title': {
                    fontWeight: 600,
                    lineHeight: 1.4,
                    fontSize: config.sm.text.title.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.text.title.fontSize
                    }
                },
                '&_content': {
                    lineHeight: 1.2,
                    fontWeight: 900,
                    fontSize: config.sm.text.content.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.text.content.fontSize,
                        ...(!hasContent && { minHeight: toRem(55) })
                    }
                }
            },
            '&_button': {
                marginTop: config.sm.button.marginTop,
                [theme.breakpoints.up('md')]: {
                    marginLeft: config.md.button?.marginLeft,
                    marginTop: config.md.button?.marginTop,
                    minWidth: config.md.button?.minWidth
                },
                [theme.breakpoints.down('md')]: {
                    maxWidth: '20%',
                    minWidth: toRem(107)
                },
                maxHeight: toRem(50),
                '&_icon': {
                    width: toRem(14),
                    height: toRem(14)
                }
            },
            '&_decoration': {
                position: 'absolute',
                bottom: 0,
                left: 0
            }
        }
    }
}

export default style
