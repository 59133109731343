const portalColors = {
    'zena.net.hr': '#B70060',
    'danas.hr': '#E3000B',
    'rtl.hr': '#E3000B',
    'net.hr': '#F15B24'
}

const getColorFromUrl = url => {
    const portal = url.replaceAll(/http(s){0,1}:|\/\/|www./gim, '').split('/')[0]
    return portalColors[portal]
}
export default getColorFromUrl
