import { keyframes } from '@emotion/react'

import { toRem, toRems } from '../../helpers/theme'
/* eslint-disable quote-props, no-dupe-keys */
const style = ({
    theme,
    buttonWidth,
    buttonWidthMobile,
    iconOnly,
    isLoading,
    variant,
    simple,
    isInverted,
    size,
    isRound
}) => {
    // for development errors
    const devWarnTxt = theme.colors.error.dev.text
    const devWarnBg = theme.colors.error.dev.background
    // for development errors

    const spinnerAnimation = () => {
        const spinner = keyframes({
            from: {
                transform: 'rotate(0)'
            },
            to: {
                transform: 'rotate(360deg)'
            }
        })
        return {
            animationName: spinner,
            animationDuration: '2s',
            animationTimingFunction: 'linear',
            animationIterationCount: 'infinite'
        }
    }

    const config = {
        icon: {
            fontSize: toRem(32),
            hSpacing: toRem(14)
        },
        small: {
            fontSize: toRem(14),
            fontWeight: theme.typography.font.weight.bold,
            xs: {
                minHeight: toRem(34),
                padding: toRems([0, 12])
            },
            md: {
                minHeight: toRem(34),
                padding: toRems([0, 12])
            }
        },
        normal: {
            fontSize: toRem(16),
            fontWeight: theme.typography.font.weight.black,
            xs: {
                minHeight: toRem(46),
                padding: toRems([8, 12])
            },
            md: {
                minHeight: toRem(50),
                padding: toRems([8, 12])
            }
        },
        borderWidth: toRem(2),
        lineHeight: 1.2,
        letterSpacing: toRem(3),
        variant: {
            alpha: {
                borderStyle: 'solid',
                borderWidth: toRem(2)
            }
        },
        reset: {
            dimensions: {
                minWidth: 0,
                minHeight: 0,
                padding: 0
            },
            border: {
                border: 'none',
                borderRadius: 0
            }
        }
    }

    return {
        fonFamily: 'Proxima Nova',
        display: 'inline-flex',
        verticalAlign: 'middle',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        minWidth: buttonWidthMobile || buttonWidth || 0,
        minHeight: config[size]?.xs?.minHeight,
        margin: 0,
        padding: config[size]?.xs?.padding,
        outline: 0,
        backgroundColor: isInverted
            ? theme.colors.button[variant]?.background?.inverted
            : theme.colors.button[variant]?.background?.default,
        color: isInverted ? theme.colors.button[variant]?.text?.inverted : theme.colors.button[variant]?.text?.default,
        borderRadius: isRound && '50%',
        borderColor: isInverted
            ? theme.colors.button[variant]?.border?.inverted
            : theme.colors.button[variant]?.border?.default,
        borderStyle: config?.variant[variant]?.borderStyle,
        borderWidth: config?.variant[variant]?.borderWidth,
        fontWeight: config[size]?.fontWeight,
        fontSize: config[size]?.fontSize,
        lineHeight: config.lineHeight,
        cursor: 'pointer',
        transitionDuration: theme.transitions.button.default.duration,
        transitionTimingFunction: theme.transitions.button.default.timingFunction,
        transitionDelay: theme.transitions.button.default.delay,
        transitionProperty: 'color, background-color, border-color',
        userSelect: 'none',
        MozAppearance: 'none',
        WebkitAppearance: 'none',
        WebkitTapHighlightColor: 'transparent',
        ...(size === 'small' && {
            borderWidth: toRem(1),
            minWidth: 'initial',
            minHeight: toRem(34)
        }),
        ...(simple && {
            verticalAlign: 'baseline',
            color: theme.colors.link[variant]?.text?.default,
            textDecoration: 'underline',
            ...config?.reset?.dimensions,
            ...config?.reset?.border,
            backgroundColor: 'transparent'
        }),
        [theme.breakpoints.up('md')]: {
            padding: config[size]?.md?.padding,
            minWidth: buttonWidth || 0,
            minHeight: config.md?.minHeight,
            ...(simple && {
                ...config?.reset?.dimensions
            }),
            ...(size === 'small' && {
                minWidth: 'initial',
                minHeight: toRem(34)
            })
        },
        ...(isLoading && {
            pointerEvents: 'none',
            backgroundColor: theme.colors.button[variant]?.background?.disabled || devWarnBg,
            color: theme.colors.button[variant]?.text?.disabled || devWarnTxt
        }),
        '& .spinner': {
            marginLeft: config.icon?.hSpacing,
            width: toRem(15),
            height: toRem(15),
            border: `2px solid ${theme.colors.button[variant]?.text?.disabled}`,
            borderRadius: '50%',
            borderTop: `2px solid ${theme.colors.button[variant]?.background?.default}`,
            ...spinnerAnimation()
        },
        '&:focus': {
            borderColor: theme.colors.button[variant]?.border?.hover,
            backgroundColor: isInverted
                ? theme.colors.button[variant]?.background?.inverted
                : theme.colors.button[variant]?.background?.hover || devWarnBg,
            color: isInverted
                ? theme.colors.button[variant]?.text?.invertedHover
                : theme.colors.button[variant]?.text?.hover || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.hover || devWarnTxt
            })
        },
        '&:hover': {
            borderColor: theme.colors.button[variant]?.border?.hover,
            backgroundColor: isInverted
                ? theme.colors.button[variant]?.background?.inverted
                : theme.colors.button[variant]?.background?.hover || devWarnBg,
            color: isInverted
                ? theme.colors.button[variant]?.text?.invertedHover
                : theme.colors.button[variant]?.text?.hover || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.hover || devWarnTxt,
                textDecoration: 'underline'
            })
        },
        '&:active': {
            borderColor: theme.colors.button[variant]?.border?.hover,
            backgroundColor: theme.colors.button[variant]?.background?.hover || devWarnBg,
            color: theme.colors.button[variant]?.text?.hover || devWarnTxt,
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.active || devWarnTxt
            })
        },
        '& .btn_icon': {
            display: 'inherit',
            fontSize: config.icon?.fontSize,
            marginLeft: iconOnly ? 0 : config.icon?.hSpacing,
            '& > span': {
                // INFO:
                // this overides any given Icon `size` prop in button
                // icon size (fontSize) need to be styled from parent component
                // ---------------------------------------------------------
                fontSize: 'inherit'
            }
        },
        '&.disabled, [disabled], [disabled="disabled"]': {
            backgroundColor: theme.colors.button[variant]?.background?.disabled || devWarnBg,
            color: theme.colors.button[variant]?.text?.disabled || devWarnTxt,
            pointerEvents: 'none',
            cursor: 'default',
            ...(!isLoading && {
                opacity: 0.6
            }),
            ...(simple && {
                backgroundColor: 'transparent',
                color: theme.colors.link[variant]?.text?.disabled || devWarnTxt
            })
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
