/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'
// <svg width="90" height="22" viewBox="0 0 90 22" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>
const Comment = createSvgIcon(
    <>
        <path
            className="colorme"
            d="M0 11.616c0-2.987 0-4.48.928-5.408.928-.928 2.421-.928 5.408-.928h3.168c2.987 0 4.48 0 5.408.928.928.928.928 2.421.928 5.408v3.168c0 2.987 0 4.48-.928 5.408-.928.928-2.421.928-5.408.928H6.336c-2.987 0-4.48 0-5.408-.928C0 19.264 0 17.771 0 14.784v-3.168z"
        />
        <path
            d="M5.325 8.493a1.056 1.056 0 011.494 0l3.96 3.96a1.056 1.056 0 010 1.494l-3.96 3.96a1.056 1.056 0 11-1.494-1.494L8.54 13.2 5.325 9.987a1.056 1.056 0 010-1.494z"
            fill="#fff"
        />
        <path
            className="colortxt"
            d="M56.37 5.878h1.004V1.754c0-.669.557-1.226 1.226-1.226.669 0 1.226.557 1.226 1.226v4.124h3.01c.668 0 1.225.557 1.225 1.226 0 .668-.557 1.226-1.226 1.226h-3.01v11.646c0 .669-.556 1.226-1.225 1.226a1.237 1.237 0 01-1.226-1.226V8.33h-1.003a1.237 1.237 0 01-1.226-1.226c0-.669.557-1.226 1.226-1.226zM20.818 13.01v6.91c0 .67.557 1.227 1.226 1.227.669 0 1.226-.558 1.226-1.226v-6.91c0-2.842 2.34-5.183 5.238-5.183 2.842 0 5.183 2.34 5.238 5.183v6.91c0 .668.558 1.226 1.226 1.226.67 0 1.226-.558 1.226-1.226v-6.91c0-4.18-3.455-7.635-7.634-7.635-4.29.056-7.746 3.455-7.746 7.635zM45.337 21.147h7.3c.669 0 1.226-.558 1.226-1.226 0-.669-.557-1.226-1.226-1.226h-7.3c-2.563 0-4.736-1.895-5.127-4.347h7.245c.669 0 1.226-.557 1.226-1.226 0-.669-.557-1.226-1.226-1.226h-7.077c.668-2.117 2.619-3.678 4.96-3.678h7.02c.67 0 1.227-.557 1.227-1.226 0-.668-.558-1.226-1.226-1.226h-7.022c-4.18 0-7.634 3.455-7.634 7.635 0 4.346 3.455 7.746 7.634 7.746zM63.058 18.416h1.059c.446 0 .78.334.78.78v1.17c0 .446-.334.78-.78.78h-1.059a.762.762 0 01-.78-.78V19.14c0-.39.334-.724.78-.724zM88.58 5.6c-3.622 0-6.575 2.953-6.575 6.575V20.7a.5.5 0 00.501.501.5.5 0 00.502-.501V11.283l.056-.278.055-.28.056-.278.112-.278.11-.279.112-.223.112-.223.167-.223.167-.223.167-.223.168-.222.167-.223.167-.168.167-.167.223-.167.223-.167.223-.167.223-.167.223-.112.222-.111.28-.112.278-.111.278-.112.279-.055.279-.056.278-.056.279-.056h.557a.5.5 0 00.502-.501c-.112-.279-.335-.446-.558-.446zM67.516.528c.279 0 .446.223.446.502v7.745C69.132 6.881 71.194 5.6 73.59 5.6c3.623 0 6.576 2.954 6.576 6.576V20.7a.5.5 0 01-.502.501c-.278 0-.445-.223-.445-.501v-8.526c0-.502-.056-.948-.168-1.393l-.055-.279a6.773 6.773 0 00-1.226-2.118c-.39-.39-.78-.724-1.226-1.003a6.445 6.445 0 00-3.177-.836c-.668.056-1.281.168-1.894.446l-.502.223c-1.17.669-2.062 1.672-2.619 3.01a4.827 4.827 0 00-.334 1.783V20.867l-.167.167c-.056.056-.168.056-.223.056-.112 0-.168-.056-.279-.112 0-.055-.056-.111-.056-.167V.974c-.278-.223-.055-.446.223-.446z"
            fill="#666766"
        />
    </>,
    'NetLogoColor'
)

export default Comment
