import { toRem } from '../../helpers'
import { getSectionColor } from '../../helpers/utils'

const style = ({ theme, variant, inverted, fontSize1: fontSize, categoryColor, isZodiac }) => {
    const { typography } = theme
    const { text } = theme.colors

    let subtitleColor
    let subtitleTextTransform

    if (variant === 'alpha') {
        subtitleColor = categoryColor || getSectionColor()
        subtitleTextTransform = 'uppercase'
    } else if (variant === 'beta') {
        subtitleColor = text.default
    }

    return {
        '.title': {
            ...(fontSize && { fontSize: toRem(fontSize) }),
            lineHeight: '1.2',

            ...(isZodiac && { marginLeft: '15px' }),
            [theme.breakpoints.up('md')]: {
                ...(isZodiac && { marginLeft: '25px' })
            },
            '&_subtitle': {
                textTransform: subtitleTextTransform,
                color: subtitleColor,
                fontFamily: typography.fontFamilyAlt,
                fontWeight: typography.fontWeight.black,
                lineHeight: '1.2'
            },
            '&_title': {
                color: inverted ? text.inverted : text.default,
                lineHeight: '1.2',
                display: 'inline'
            }
        }
    }
}

export default style
