/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { memo } from 'react'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import Head from 'next/head'
import PropTypes from 'prop-types'

import ImageAuthor from '@hmn/rtl-web-core/components/Image/ImageAuthor.component'
import getVariationData from '@hmn/rtl-web-core/helpers/image-variation'
import { useImagesConfig } from '@hmn/rtl-web-core/hooks'
import { globalSettings } from '@hmn/rtl-web-core/settings'

import { ProgressiveImageStyled } from './components/ProgressiveImage/ProgressiveImage.style'
import styles from './Image.style'

const { constants } = globalSettings
const { IMAGES_TRANSITION_DURATION_MS } = constants

const imageRatioVariants = Object.freeze({
    CUSTOM_SQUARE: {
        aspect_ratio_name: 'Ratio 1:1',
        id: '87d630da-92c1-11eb-822a-0242ac120012',
        name: '[custom] Square',
        slug: 'custom-square'
    },
    CUSTOM_WIDE_SCREEN: {
        aspect_ratio_name: 'Ratio 16:9',
        id: 'ba03ebd6-9d09-11eb-8a41-cedfb09875dd',
        method: 'fill',
        name: '[custom] Wide-screen',
        slug: 'custom-wide-screen'
    },
    CUSTOM_ULTRA_WIDE: {
        aspect_ratio_name: 'Ratio 21:9',
        id: 'e6f823a4-9d0a-11eb-a11c-cedfb09875dd',
        method: 'fill',
        name: '[custom] Ultra-wide',
        slug: 'custom-ultra-wide'
    },
    CUSTOM_LANDSCAPE: {
        aspect_ratio_name: 'Ratio 3:2',
        id: '6f1b75b0-9d0b-11eb-b4b2-cedfb09875dd',
        method: 'fill',
        name: '[custom] Landscape',
        slug: 'custom-landscape'
    },
    CUSTOM_PORTRAIT: {
        aspect_ratio_name: 'Ratio 2:3',
        id: 'e3458688-9d0b-11eb-b2f5-cedfb09875dd',
        method: 'fill',
        name: '[custom] Portrait',
        slug: 'custom-portrait'
    },
    CUSTOM_ORIGINAL: {
        aspect_ratio_name: null,
        id: '42bc9a34-9d0c-11eb-a404-cedfb09875dd',
        method: 'fit',
        name: '[custom] No aspect ratio image',
        slug: 'custom-no-aspect-ratio-image'
    }
})

const ImageStyled = styled.picture(props => ({ ...styles(props) }))

const GalleryImageStyled = styled.div(props => ({ ...styles(props) }))
function Image({
    image,
    placeholder: initialPlaceholder,
    placeholderBgColor,
    type: initialType,
    width: initialWidth,
    height: initialHeight,
    method: initialMethod,
    variation: initialVariation,
    alt,
    title,
    className,
    classNameProgressive,
    rounded,
    lazyLoad,
    ratio: initialRatio,
    fullHeight,
    hideAuthor,
    updatedAt,
    preload,
    isGalleryLazyLoadImage,
    useLargestInstance,
    isHeadImage,
    original,
    sizesSm,
    sizesMd,
    sizesXl,
    originalAspectRatio,
    minSrcsetWidth,
    maxSrcsetWidth,
    ...other
}) {
    const isAmp = useAmp()
    const { imagesConfig: variations = [], isLoaded } = useImagesConfig()

    const { source, placeholder, ratio, width, height, srcSetData, max_width, max_height } = getVariationData(
        image,
        initialWidth,
        initialHeight,
        initialRatio,
        initialVariation,
        variations,
        original,
        imageRatioVariants,
        isLoaded,
        isAmp,
        useLargestInstance,
        initialType,
        updatedAt,
        sizesSm,
        sizesMd,
        sizesXl,
        originalAspectRatio,
        minSrcsetWidth,
        maxSrcsetWidth
    )

    if (isGalleryLazyLoadImage) {
        return (
            <ProgressiveImageStyled
                className={classNameProgressive}
                imageHeight={height}
                imageWidth={width}
                imageMaxWidth={max_width} // @NOTE: not used at the moment
                imageMaxHeight={max_height} // @NOTE: not used at the moment
                ratio={ratio}>
                <GalleryImageStyled
                    imageWidth={width}
                    imageHeight={height}
                    className={className}
                    rounded={rounded}
                    placeholderBgColor={placeholderBgColor}
                    withPlaceholder
                    id="gallery_image"
                    transitionDuration={IMAGES_TRANSITION_DURATION_MS}
                    {...other}>
                    {srcSetData ? (
                        <img
                            data-src={source}
                            data-srcset={`${srcSetData?.webp}, ${srcSetData?.jpeg}`}
                            alt={alt || title || 'Image'}
                            className="swiper-lazy"
                            height={height}
                            width={width}
                        />
                    ) : (
                        <img
                            data-src={source}
                            alt={alt || title || 'Image'}
                            className="swiper-lazy"
                            height={height}
                            width={width}
                        />
                    )}

                    <ImageAuthor
                        source={image?.source || image?.extended_attributes?.source}
                        hideAuthor={hideAuthor}
                        author={image?.extended_attributes?.author || image?.source}
                    />
                </GalleryImageStyled>
            </ProgressiveImageStyled>
        )
    }

    return (
        <ProgressiveImageStyled
            className={classNameProgressive}
            imageHeight={height}
            imageWidth={width}
            imageMaxWidth={max_width} // @NOTE: not used at the moment
            imageMaxHeight={max_height} // @NOTE: not used at the moment
            ratio={ratio}>
            <ImageStyled
                imageWidth={width}
                imageHeight={height}
                className={className}
                rounded={rounded}
                isImageError={!source}
                {...other}>
                <>
                    {srcSetData && (
                        <>
                            {srcSetData?.webp && (
                                <source type="image/webp" srcSet={srcSetData.webp} sizes={srcSetData?.sizes} />
                            )}
                            {srcSetData?.jpeg && (
                                <source srcSet={srcSetData.jpeg} type="image/jpeg" sizes={srcSetData?.sizes} />
                            )}
                        </>
                    )}
                    <img
                        src={placeholder || '/image-placeholder-net.svg'}
                        alt={alt || title || 'Image'}
                        loading={lazyLoad ? 'lazy' : undefined}
                        height={`${parseInt(height, 10)}px`}
                        width={`${parseInt(width, 10)}px`}
                    />
                    {preload && (
                        <Head>
                            {srcSetData ? (
                                <link
                                    rel="preload"
                                    as="image"
                                    href={placeholder}
                                    imageSrcSet={`${srcSetData.webp}, ${srcSetData.jpeg}`}
                                    imageSizes={srcSetData.sizes}
                                />
                            ) : (
                                <link
                                    rel="preload"
                                    as="image"
                                    href={placeholder}
                                    imageSizes="(min-width: 768px) calc(700px), calc(100vw + 380px)"
                                />
                            )}
                        </Head>
                    )}
                </>
                <ImageAuthor
                    source={image?.source || image?.extended_attributes?.source}
                    hideAuthor={hideAuthor}
                    author={image?.extended_attributes?.author || image?.source}
                />
            </ImageStyled>
        </ProgressiveImageStyled>
    )
}

const imageRoundedVariants = Object.freeze({
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    ROUNDED: 'rounded'
})

const imageFillVariants = Object.freeze({
    FILL: 'fill',
    FIT: 'fit'
})

const imageTypeVariants = Object.freeze({
    JPG: 'jpg',
    WEBP: 'webp'
})

Image.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), () => null]),
    placeholder: PropTypes.string,
    type: PropTypes.oneOf([...Object.values(imageTypeVariants)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    method: PropTypes.oneOf([...Object.values(imageFillVariants)]),
    variation: PropTypes.oneOfType([PropTypes.shape({}), () => null]),
    alt: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    classNameProgressive: PropTypes.string,
    rounded: PropTypes.oneOf([...Object.values(imageRoundedVariants)]),
    lazyLoad: PropTypes.bool,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fullHeight: PropTypes.bool,
    placeholderBgColor: PropTypes.string,
    hideAuthor: PropTypes.bool,
    updatedAt: PropTypes.string,
    preload: PropTypes.bool,
    isGalleryLazyLoadImage: PropTypes.bool,
    useLargestInstance: PropTypes.bool,
    isHeadImage: PropTypes.bool,
    original: PropTypes.bool,
    /**
     * Sizes for the image in the small breakpoints.
     * If number is provided, it signifies the percentage of the screen that the image takes up (eg. 66 = 66vw)
     * If not provided automatic sizes are used
     */
    sizesSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Sizes for the image in the small breakpoints.
     * If number is provided, it signifies the percentage of the screen that the image takes up (eg. 66 = 66vw)
     * If not provided automatic sizes are used
     */
    sizesMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * Sizes for the image in the largest breakpoints.
     * If number is provided, it signifies the percentage of the screen that the image takes up (eg. 66 = 66vw)
     * If not provided automatic sizes are used
     */
    sizesXl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    originalAspectRatio: PropTypes.number,
    minSrcsetWidth: PropTypes.number,
    maxSrcsetWidth: PropTypes.number
}

Image.defaultProps = {
    image: undefined,
    placeholder: undefined,
    type: undefined,
    width: 480,
    height: 480,
    method: 'fill',
    variation: imageRatioVariants.CUSTOM_LANDSCAPE,
    alt: undefined,
    title: undefined,
    className: undefined,
    classNameProgressive: undefined,
    rounded: undefined,
    lazyLoad: true,
    ratio: undefined,
    fullHeight: false,
    placeholderBgColor: undefined,
    hideAuthor: false,
    updatedAt: undefined,
    preload: undefined,
    isGalleryLazyLoadImage: false,
    useLargestInstance: undefined,
    isHeadImage: false,
    original: false,
    sizesSm: undefined,
    sizesMd: undefined,
    sizesXl: undefined,
    originalAspectRatio: undefined,
    minSrcsetWidth: undefined,
    maxSrcsetWidth: undefined
}

const imagePropsAreEqual = (prevImage, nextImage) =>
    prevImage?.image === nextImage?.image && prevImage?.ratio === nextImage?.ratio

export { imageRoundedVariants, imageRatioVariants }

export default memo(Image, imagePropsAreEqual)
