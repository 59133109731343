/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const YoutubeNet = createSvgIcon(
    <>
        <path
            d="M17.1554 3.01764C17.0663 2.66169 16.8849 2.33557 16.6294 2.07219C16.3739 1.80882 16.0535 1.61753 15.7004 1.51764C14.4104 1.20264 9.25043 1.20264 9.25043 1.20264C9.25043 1.20264 4.09043 1.20264 2.80043 1.54764C2.44736 1.64753 2.12691 1.83882 1.87144 2.10219C1.61597 2.36557 1.43452 2.69169 1.34543 3.04764C1.10934 4.3568 0.993856 5.68487 1.00043 7.01514C0.992013 8.35542 1.1075 9.69361 1.34543 11.0126C1.44365 11.3575 1.62916 11.6712 1.88404 11.9235C2.13891 12.1757 2.45454 12.358 2.80043 12.4526C4.09043 12.7976 9.25043 12.7976 9.25043 12.7976C9.25043 12.7976 14.4104 12.7976 15.7004 12.4526C16.0535 12.3527 16.3739 12.1615 16.6294 11.8981C16.8849 11.6347 17.0663 11.3086 17.1554 10.9526C17.3897 9.65333 17.5052 8.33539 17.5004 7.01514C17.5088 5.67485 17.3934 4.33666 17.1554 3.01764Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.56291 9.46763L11.8754 7.01513L7.56291 4.56263V9.46763Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>,
    'YoutubeNet'
)

export default YoutubeNet
