/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Gemini = createSvgIcon(
    <>
        <defs />
        <path
            fill="#50B694"
            d="M19 18.5c-1.2-.7-2.3-1.3-3.5-1.7V5.2a16.4 16.4 0 006.5-4L20.7 0a14.5 14.5 0 01-9.6 4.3C8 4.3 5.6 3 4 2A15 15 0 011.1 0L0 1.2a16.5 16.5 0 006.5 4v11.7a17 17 0 00-6.5 4L1.2 22a15 15 0 019.9-4.2c3 0 5.4 1.1 7 2 1.6 1.1 2.6 2.2 2.6 2.2l1.3-1.2s-1.1-1.2-3-2.3zM8.2 16.4V5.7a13.7 13.7 0 005.6 0v10.7a13.6 13.6 0 00-5.6 0z"
        />
    </>,
    'Gemini'
)

export default Gemini
