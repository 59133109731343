/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Virgo = createSvgIcon(
    <>
        <defs />
        <path
            fill="#E52565"
            d="M19 18.8l-.8-1.3c.5-.6.9-1 1.3-1.7 1.6-2.3 2.5-5 2.5-7.5a3.5 3.5 0 00-3.6-3.6c-.7 0-1.3.2-1.9.6V3.6A3.5 3.5 0 0012.9 0c-1 0-2 .5-2.7 1.3A3.6 3.6 0 005 1a2.8 2.8 0 00-5 1.8v1.8h1.7V2.8a1.1 1.1 0 012.3 0V17h1.7V3.6a1.8 1.8 0 013.7 0V17H11V3.6c0-1 .8-1.9 1.8-1.9.5 0 1 .2 1.3.5.4.4.6.9.6 1.4v4.7a38.9 38.9 0 001.4 8.8c-1.3 1.3-3 2.4-5 3.3l.7 1.6c1.9-.8 3.6-2 5-3.3l.8 1.2c1.1 1.4 2.6 2.1 4.3 2.1v-1.7c-1.2 0-2.2-.5-3-1.5zm-.6-12.4c.5 0 1 .2 1.3.6.4.3.6.8.6 1.3 0 2.2-1 4.9-2.8 7.3l-.2-.8c-.4-2-.6-4.3-.7-6.5a2 2 0 011.8-1.9z"
        />
    </>,
    'Virgo'
)

export default Virgo
