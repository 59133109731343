// @NOTE: Graphic sizes are in px so they don't brake with rescaling
/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant, widthXs, heightXs, widthMd, heightMd, fillVariant }) => {
    const devWarnBg = theme.colors.error.dev.background

    const getSVGFill = color => (color ? color.replace('#', '') : devWarnBg.replace('#', ''))

    // @TODO: NET THEME
    const fillColor = {
        variant: {
            default: {
                fill: theme.colors.decoration.graphic.default
            },
            alternative: {
                fill: theme.colors.decoration.graphic.alternative
            }
        }
    }

    const fill = fillColor?.variant[fillVariant]?.fill

    const config = {
        variant: {
            alpha: {
                background: `url("data:image/svg+xml,%3Csvg width='540' height='10' viewBox='0 0 540 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.31812 36.682L-46.1819 -10.818L-39.8179 -17.182L7.68208 30.318L1.31812 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.8181 36.682L-24.6819 -10.818L-18.3179 -17.182L29.1821 30.318L22.8181 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M44.3181 36.682L-3.18188 -10.818L3.18208 -17.182L50.6821 30.318L44.3181 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M65.8181 36.682L18.3181 -10.818L24.6821 -17.182L72.1821 30.318L65.8181 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M87.3181 36.682L39.8181 -10.818L46.1821 -17.182L93.6821 30.318L87.3181 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M109.318 36.682L61.8181 -10.818L68.1821 -17.182L115.682 30.318L109.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M131.318 36.682L83.8181 -10.818L90.1821 -17.182L137.682 30.318L131.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M153.318 36.682L105.818 -10.818L112.182 -17.182L159.682 30.318L153.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M175.318 36.682L127.818 -10.818L134.182 -17.182L181.682 30.318L175.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M197.318 36.682L149.818 -10.818L156.182 -17.182L203.682 30.318L197.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M219.318 36.682L171.818 -10.818L178.182 -17.182L225.682 30.318L219.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M241.318 36.682L193.818 -10.818L200.182 -17.182L247.682 30.318L241.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M263.318 36.682L215.818 -10.818L222.182 -17.182L269.682 30.318L263.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M285.318 36.682L237.818 -10.818L244.182 -17.182L291.682 30.318L285.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M307.318 36.682L259.818 -10.818L266.182 -17.182L313.682 30.318L307.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M329.318 36.682L281.818 -10.818L288.182 -17.182L335.682 30.318L329.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M351.318 36.682L303.818 -10.818L310.182 -17.182L357.682 30.318L351.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M373.318 36.682L325.818 -10.818L332.182 -17.182L379.682 30.318L373.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M395.318 36.682L347.818 -10.818L354.182 -17.182L401.682 30.318L395.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M417.318 36.682L369.818 -10.818L376.182 -17.182L423.682 30.318L417.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M439.318 36.682L391.818 -10.818L398.182 -17.182L445.682 30.318L439.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M461.318 36.682L413.818 -10.818L420.182 -17.182L467.682 30.318L461.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M483.318 36.682L435.818 -10.818L442.182 -17.182L489.682 30.318L483.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M505.318 36.682L457.818 -10.818L464.182 -17.182L511.682 30.318L505.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M527.318 36.682L479.818 -10.818L486.182 -17.182L533.682 30.318L527.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M549.318 36.682L501.818 -10.818L508.182 -17.182L555.682 30.318L549.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M571.318 36.682L523.818 -10.818L530.182 -17.182L577.682 30.318L571.318 36.682Z' fill='%23${getSVGFill(
                    fill
                )}'/%3E%3C/svg%3E%0A")`,
                widthXs: widthXs || 108,
                heightXs: heightXs || 10,
                widthMd: widthMd || 164,
                heightMd: heightMd || 10
            }
        }
    }

    return {
        background: config?.variant[variant]?.background,
        width: config?.variant[variant]?.widthXs,
        height: config?.variant[variant]?.heightXs,
        pointerEvents: 'none',
        [theme.breakpoints.up('md')]: {
            width: config?.variant[variant]?.widthMd,
            height: config?.variant[variant]?.heightMd
        }
    }
}

export default style
