import { useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import getColorFromUrl from '@hmn/rtl-web-core/helpers/theme/getColorFromUrl'
import { useGTM, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { ContentMarker, contentMarkerSizeVariants, contentMarkerVariants } from '../../../ContentMarker'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import { Link } from '../../../Link'
import styles from './Card.style'
import { articleCardContentMarkerVariants, articleCardSizeVariants, articleCardVariants } from './Card.variants'

const ArticleCardStyled = styled.div(props => ({ ...styles(props) }))

function ArticleCard({
    item,
    cardId,
    imageWidth,
    imageHeight,
    imageVariation,
    imageLazyLoad,
    titleTagComponent: TitleTag,
    href,
    hrefAs,
    className,
    variant,
    sizeVariant,
    contentMarkerVariant,
    gtmData,
    showLead,
    categoryColor,
    embedded,
    urlImageSrc,
    isArchive,
    subtitleDisable,
    imageWidth135,
    isExternal,
    imagePreload,
    onClick,
    children,
    useUrlColor,
    imageSizesSm,
    imageSizesMd,
    reverseAlign,
    ...rest
}) {
    const { title, subtitle, image, thumbnail, name, taxons } = item
    const cardTitle = title || name
    const cardSubtitle = subtitleDisable ? '' : subtitle || item?.extended_attributes?.subtitle
    const cardImage = image || thumbnail
    const cardCategory = taxons?.sitemap?.[0]?.path?.[0].slug
    const isLive = taxons?.['article-type']?.[0]?.title === 'Live'
    const titleHtmlProps = useInnerHtml(cardTitle)
    const subtitleHtmlProps = useInnerHtml(cardSubtitle)
    const { sendDataToGTM } = useGTM()
    const articleDate = useMemo(
        () => `${dayjs(item?.published_at?.toString().replace('00:00', '02:00')).format('D.M.YYYY.')}`,
        [item]
    )
    const hasVideo = !!item?.extended_attributes?.header_video_url || !!item?.extended_attributes?.video

    const handleClick = useCallback(() => {
        if (gtmData) {
            sendDataToGTM(gtmData)
        }
        onClick?.()
    }, [gtmData, onClick])

    if (!cardTitle) {
        return null
    }

    return (
        <ArticleCardStyled
            id={cardId}
            variant={variant}
            sizeVariant={sizeVariant}
            contentMarkerVariant={contentMarkerVariant}
            className={`articleCard ${variant} is_size${sizeVariant} ${
                cardCategory && `is_${cardCategory}`
            }${className} ${isLive ? 'is_live' : ''}`}
            data-upscore-object-id={item.id}
            categoryColor={
                (useUrlColor &&
                    getColorFromUrl(href || item?.extended_attributes?.external_permalink || item?.sitemap?.href)) ||
                categoryColor
            }
            embedded={embedded}
            isArchive={isArchive}
            imageWidth135={imageWidth135}
            reverseAlign={reverseAlign}
            {...rest}>
            <Link
                href={href || item?.extended_attributes?.external_permalink || item?.sitemap?.href}
                as={hrefAs || item?.extended_attributes?.external_permalink || item?.sitemap?.href}
                isExternal={isExternal || (!hrefAs && !!item?.extended_attributes?.external_permalink)}
                title={cardTitle}
                className="cardInner"
                data-upscore-url
                onClick={handleClick}>
                {variant !== 'gamma' && (
                    <div className="cardImage" data-before-content="DANAS">
                        {isLive && (
                            <div className="cardContent_live">
                                <span className="dot" />
                                Uživo
                            </div>
                        )}
                        <Image
                            image={urlImageSrc || cardImage}
                            updatedAt={image?.updated_at}
                            variation={
                                sizeVariant === '1x1' && variant === 'alpha' && !imageVariation
                                    ? imageRatioVariants.CUSTOM_ULTRA_WIDE
                                    : imageVariation
                            }
                            sizesSm={imageSizesSm}
                            sizesMd={imageSizesMd}
                            width={sizeVariant === '1x1' && !imageVariation ? 1400 : imageWidth}
                            height={sizeVariant === '1x1' && !imageVariation ? 600 : imageHeight}
                            hideAuthor
                            lazyLoad={!!imageLazyLoad}
                            preload={!!imagePreload}
                        />
                        {(contentMarkerVariant !== 'ARTICLE' || hasVideo) && (
                            <div className="contentMarker_wrapper">
                                <ContentMarker
                                    id="article_card_content_marker"
                                    tiny={variant === 'beta' && (sizeVariant === '1x6' || sizeVariant === '1x4')}
                                    sizeVariant={
                                        sizeVariant === '2x3' && variant === 'alpha'
                                            ? contentMarkerSizeVariants['1x2']
                                            : contentMarkerSizeVariants[sizeVariant]
                                    }
                                    variant={
                                        hasVideo
                                            ? contentMarkerVariants.VIDEO
                                            : contentMarkerVariants[contentMarkerVariant]
                                    }
                                    categoryColor={categoryColor}
                                />
                            </div>
                        )}
                    </div>
                )}
                {(cardSubtitle || cardTitle) && (
                    <div className="cardContent">
                        {isArchive && <span className="cardContent_date">{articleDate}</span>}
                        <div className="cardContent_titleWrapper" id="article_card_title_wrapper">
                            {cardSubtitle && (
                                <span
                                    id="article_card_subtitle"
                                    className="cardContent_subtitle"
                                    {...subtitleHtmlProps}
                                />
                            )}
                            {cardTitle && cardSubtitle && <span className="cardContent_separator"> / </span>}
                            {cardTitle && (
                                <TitleTag
                                    id="article_card_title"
                                    className="cardContent_title"
                                    data-upscore-title=""
                                    {...titleHtmlProps}
                                />
                            )}
                            {item?.lead && (showLead || (sizeVariant === '1x1' && variant === 'beta')) && (
                                <div>
                                    <HtmlContent className="cardContent_lead" data={item.lead} />
                                    <div>{item.lead}</div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Link>
            {children}
        </ArticleCardStyled>
    )
}
ArticleCard.propTypes = {
    item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    titleTagComponent: PropTypes.elementType,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageVariation: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), () => null]),
    href: PropTypes.string,
    hrefAs: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleCardVariants)]),
    sizeVariant: PropTypes.oneOf([...Object.values(articleCardSizeVariants)]),
    contentMarkerVariant: PropTypes.oneOf([...Object.values(articleCardContentMarkerVariants)]),
    gtmData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imageLazyLoad: PropTypes.bool,
    showLead: PropTypes.bool,
    categoryColor: PropTypes.string,
    embedded: PropTypes.bool,
    urlImageSrc: PropTypes.string,
    isArchive: PropTypes.bool,
    imageWidth135: PropTypes.bool,
    isExternal: PropTypes.bool,
    imagePreload: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    subtitleDisable: PropTypes.bool,
    useUrlColor: PropTypes.bool,
    onClick: PropTypes.func,
    imageSizesSm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageSizesMd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reverseAlign: PropTypes.bool,
    cardId: PropTypes.string
}

ArticleCard.defaultProps = {
    titleTagComponent: 'h2',
    imageWidth: 390,
    imageHeight: 360,
    imageVariation: undefined,
    href: undefined,
    hrefAs: undefined,
    className: '',
    variant: articleCardVariants.ALPHA,
    sizeVariant: articleCardSizeVariants['1x6'],
    contentMarkerVariant: articleCardContentMarkerVariants.ARTICLE,
    gtmData: undefined,
    imageLazyLoad: true,
    showLead: false,
    categoryColor: undefined,
    embedded: false,
    urlImageSrc: undefined,
    isArchive: false,
    imageWidth135: false,
    isExternal: false,
    imagePreload: false,
    subtitleDisable: false,
    onClick: undefined,
    useUrlColor: false,
    imageSizesSm: undefined,
    imageSizesMd: undefined,
    reverseAlign: false,
    cardId: undefined
}

export default withErrorBoundary(ArticleCard, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleCard]: ', error, componentStack)
    }
})
