import { toRem, toRems } from '../../../../../helpers/theme'

const style = ({ theme, pollVariant, votePercentage, isSelected }) => {
    const config = {
        sm: {
            alpha: {
                width: '100%',
                alignItems: 'center',
                fontSize: toRem(16),
                height: toRem(50),
                info: {
                    height: '100%'
                }
            },
            beta: {
                fontSize: toRem(14),
                height: '100%',
                alignItems: 'flex-start',
                image: {
                    height: `${toRem(130)}` // !important`
                },
                content: {
                    position: 'relative',
                    flexDirection: 'column'
                },
                info: {
                    height: toRem(82)
                }
            }
        },
        md: {
            alpha: {
                width: '100%',
                alignItems: 'center',
                fontSize: toRem(16),
                height: toRem(50),
                info: {
                    height: '100%'
                }
            },
            beta: {
                fontSize: toRem(16),
                alignItems: 'flex-start',
                height: '100%',
                image: {
                    height: `${toRem(200)}` // !important`
                },
                content: {
                    position: 'relative',
                    flexDirection: 'column'
                },
                info: {
                    height: toRem(95)
                }
            }
        },
        select: {
            alpha: {
                width: toRem(26),
                height: toRem(26),
                borderRadius: '25%',
                backgroundColor: votePercentage
                    ? 'transparent'
                    : theme.colors.poll.alpha.select.background[isSelected ? 'active' : 'default'],
                fill: isSelected
                    ? theme.colors.poll.alpha.select.fill.active
                    : theme.colors.poll.alpha.select.fill.default,
                color: isSelected
                    ? theme.colors.poll.alpha.select.color.active
                    : theme.colors.poll.alpha.select.color.default,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            beta: {
                width: votePercentage ? toRem(50) : toRem(30),
                height: toRem(30),
                backgroundColor: isSelected
                    ? theme.colors.poll.beta.select.background.active
                    : theme.colors.poll.beta.select.background.default,
                fill: isSelected
                    ? theme.colors.poll.beta.select.fill.active
                    : theme.colors.poll.beta.select.fill.default,
                color: isSelected
                    ? theme.colors.poll.beta.select.color.active
                    : theme.colors.poll.beta.select.color.default,
                position: 'absolute',
                left: 0,
                top: toRem(-30),
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }
        }
    }
    return {
        backgroundColor: theme.colors.poll.item.background,
        // height: config?.sm[pollVariant]?.height,
        margin: toRems([0, 10, 15, 0]),
        fontWeight: isSelected ? 700 : 400,
        fontSize: config.sm[pollVariant]?.fontSize,
        width: config?.sm[pollVariant]?.width,
        color: isSelected ? theme.colors.poll.item.color.active : theme.colors.poll.item.color.default,
        [theme.breakpoints.up('md')]: {
            fontSize: config.md[pollVariant]?.fontSize,
            width: config?.md[pollVariant]?.width
        },
        '& .pollItem': {
            display: 'flex',
            height: '100%',
            alignItems: config?.sm[pollVariant]?.alignItems,
            position: config?.sm[pollVariant]?.content?.position,
            flexDirection: config?.sm[pollVariant]?.content?.flexDirection,
            [theme.breakpoints.up('md')]: {
                alignItems: config?.sm[pollVariant]?.alignItems,
                position: config?.md[pollVariant]?.content?.position,
                flexDirection: config?.md[pollVariant]?.content?.flexDirection
            },
            '&:hover': {
                cursor: 'pointer'
            },
            '&_image': {
                height: config?.sm[pollVariant]?.image?.height,
                [theme.breakpoints.up('md')]: {
                    height: config?.md[pollVariant]?.image?.height
                }
            },
            '&_info': {
                height: config?.sm[pollVariant]?.info.height || '100%',
                width: config?.info?.pollVariant[pollVariant]?.width || '100%',
                position: 'relative',
                [theme.breakpoints.up('md')]: {
                    height: config?.md[pollVariant]?.info.height || '100%'
                },
                '&_head': {
                    display: 'flex',
                    alignItems: config?.sm[pollVariant]?.alignItems,
                    // height: 'calc(100% - 5px)',
                    padding: toRems([0, 15, 0, 20]),
                    justifyContent: 'space-between',
                    lineHeight: 1.4,
                    '&_vote': {
                        fontWeight: 900
                    },
                    '&_title': {
                        padding: toRems([10, 0])
                    }
                },
                '&_votes': {
                    width: '100%',
                    backgroundColor: 'white',
                    '&_bar': {
                        background: theme.colors.poll[pollVariant]?.votes.background.default,
                        width: `${votePercentage}%`,
                        height: toRem(5)
                    }
                }
            },
            '&_select': {
                width: config?.select[pollVariant]?.width,
                height: config?.select[pollVariant]?.height,
                borderRadius: config?.select[pollVariant]?.borderRadius,
                backgroundColor: config?.select[pollVariant]?.backgroundColor,
                color: config?.select[pollVariant]?.color,
                position: config?.select[pollVariant]?.position,
                left: config?.select[pollVariant]?.left,
                top: config?.select[pollVariant]?.top,
                display: config?.select[pollVariant]?.display,
                justifyContent: config?.select[pollVariant]?.justifyContent,
                alignItems: config?.select[pollVariant]?.alignItems,
                flexShrink: 0,
                '&_item': {
                    '& path': {
                        fill: config?.select[pollVariant]?.fill
                    }
                }
            }
        }
    }
}

export default style
