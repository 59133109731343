import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
// eslint-disable-next-line
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { SocialShareButton } from '..'
import styles from './Share.style'

const ShareStyled = styled.ul(props => ({ ...styles(props) }))

function Share({ shareUrl, social, className, ...rest }) {
    const uid = useUIDSeed()
    const router = useRouter()
    const { asPath, pathname } = router || {}

    if (!social?.length) {
        return null
    }

    const url = shareUrl || `${process.env.NEXT_PUBLIC_APP_ROOT_NET}${asPath || pathname}`

    return (
        <ShareStyled id="share_wrapper" className={className} {...rest}>
            {social.map((item, index) => (
                <SocialShareButton id={`share_${item}`} social={item} shareUrl={url} key={uid(item.id || index)} />
            ))}
        </ShareStyled>
    )
}

Share.propTypes = {
    className: PropTypes.string,
    shareUrl: PropTypes.string,
    social: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.string)])
}

Share.defaultProps = {
    className: undefined,
    shareUrl: undefined,
    social: ['facebook', 'twitterX', 'viber', 'whatsapp', 'url']
}

export default withErrorBoundary(Share, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SocialShare]: ', error, componentStack)
    }
})
