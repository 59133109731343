/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ArrowSmall = createSvgIcon(
    <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9393 12.0607L8 7.12132L10.1213 5L17.182 12.0607L10.1213 19.1213L8 17L12.9393 12.0607Z"
    />,
    'ArrowSmall'
)

export default ArrowSmall
