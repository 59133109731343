/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'
// viewBox="0 0 70 22"
const Comment = createSvgIcon(
    <>
        <path d="M36.371 5.87773H37.3741V1.75404C37.3741 1.08533 37.9313 0.528076 38.6 0.528076C39.2687 0.528076 39.826 1.08533 39.826 1.75404V5.87773H42.8352C43.5039 5.87773 44.0611 6.43499 44.0611 7.1037C44.0611 7.7724 43.5039 8.32966 42.8352 8.32966H39.826V19.9763C39.826 20.645 39.2687 21.2023 38.6 21.2023C37.9313 21.2023 37.3741 20.645 37.3741 19.9763V8.32966H36.371C35.7023 8.32966 35.145 7.7724 35.145 7.1037C35.145 6.43499 35.7023 5.87773 36.371 5.87773Z" />
        <path d="M0.818115 13.0106V19.9206C0.818115 20.5893 1.37537 21.1466 2.04408 21.1466C2.71279 21.1466 3.27004 20.5893 3.27004 19.9206V13.0106C3.27004 10.1686 5.61052 7.82815 8.50825 7.82815C11.3503 7.82815 13.6907 10.1686 13.7465 13.0106V19.9206C13.7465 20.5893 14.3037 21.1466 14.9724 21.1466C15.6411 21.1466 16.1984 20.5893 16.1984 19.9206V13.0106C16.1984 8.83121 12.7434 5.37622 8.56397 5.37622C4.2731 5.43195 0.818115 8.83121 0.818115 13.0106Z" />
        <path d="M25.3373 21.1467H32.6373C33.306 21.1467 33.8633 20.5894 33.8633 19.9207C33.8633 19.252 33.306 18.6948 32.6373 18.6948H25.3373C22.7739 18.6948 20.6006 16.8001 20.2105 14.3482H27.4549C28.1236 14.3482 28.6808 13.7909 28.6808 13.1222C28.6808 12.4535 28.1236 11.8962 27.4549 11.8962H20.3777C21.0464 9.77866 22.9968 8.21835 25.3373 8.21835H32.3587C33.0274 8.21835 33.5847 7.66109 33.5847 6.99238C33.5847 6.32367 33.0274 5.76642 32.3587 5.76642H25.3373C21.1579 5.76642 17.7029 9.22141 17.7029 13.4008C17.7029 17.7474 21.1579 21.1467 25.3373 21.1467Z" />
        <path d="M43.0582 18.416H44.117C44.5628 18.416 44.8972 18.7503 44.8972 19.1961V20.3664C44.8972 20.8122 44.5628 21.1465 44.117 21.1465H43.0582C42.6124 21.1465 42.2781 20.8122 42.2781 20.3664V19.1404C42.2781 18.7503 42.6124 18.416 43.0582 18.416Z" />
        <path d="M68.5803 5.59906C64.9581 5.59906 62.0046 8.55252 62.0046 12.1747V14.1808V14.5152V15.4625V19.8091V20.7007C62.0046 20.9793 62.2275 21.2022 62.5062 21.2022C62.7848 21.2022 63.0077 20.9793 63.0077 20.7007V19.8091V15.4625V14.1251V12.1747V11.8403V11.5617V11.2831L63.0634 11.0044L63.1191 10.7258L63.1749 10.4472L63.2863 10.1686L63.3978 9.88993L63.5092 9.66703L63.6207 9.44412L63.7879 9.22122L63.955 8.99832L64.1222 8.77542L64.2894 8.55252L64.4566 8.32961L64.6237 8.16244L64.7909 7.99526L65.0138 7.82808L65.2367 7.66091L65.4596 7.49373L65.6825 7.32655L65.9054 7.2151L66.1283 7.10365L66.407 6.9922L66.6856 6.88075L66.9642 6.7693L67.2428 6.71357L67.5215 6.65785L67.8001 6.60212L68.0787 6.54639H68.3573H68.636C68.9146 6.54639 69.1375 6.32349 69.1375 6.04486C69.0261 5.76624 68.8032 5.59906 68.5803 5.59906Z" />
        <path d="M47.5162 0.528076C47.7949 0.528076 47.962 0.750978 47.962 1.02961V8.77546C49.1323 6.88079 51.1941 5.5991 53.5903 5.5991C57.2125 5.5991 60.1659 8.55256 60.1659 12.1747V14.1808V14.2366V15.1839V19.8091V20.7007C60.1659 20.9794 59.943 21.2023 59.6644 21.2023C59.3858 21.2023 59.2186 20.9794 59.2186 20.7007V19.8091V15.2396V14.1251V12.1747C59.2186 11.6732 59.1629 11.2274 59.0514 10.7816L58.9957 10.503C58.7171 9.7228 58.327 9.05409 57.7697 8.38538C57.3796 7.99531 56.9896 7.66095 56.5438 7.38232C55.5407 6.82507 54.4819 6.54644 53.3674 6.54644C52.6987 6.60217 52.0857 6.71362 51.4727 6.99224L50.9712 7.21515C49.801 7.88385 48.9094 8.88691 48.3521 10.2243C48.1292 10.7816 48.0178 11.3946 48.0178 12.0075V15.1282V19.7534V20.4221V20.7007C48.0178 20.7565 48.0178 20.8122 48.0178 20.8679C47.962 20.9236 47.9063 20.9794 47.8506 21.0351C47.7949 21.0908 47.6834 21.0908 47.6277 21.0908C47.5162 21.0908 47.4605 21.0351 47.3491 20.9794C47.3491 20.9236 47.2933 20.8679 47.2933 20.8122V20.3664V19.8091V14.2366V12.119V0.973881C47.0147 0.750979 47.2376 0.528076 47.5162 0.528076Z" />
    </>,
    'NetTitleOnly'
)

export default Comment
