/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
// import { useRouter } from 'next/router'
import { PropTypes } from 'prop-types'

import { AmpIframe } from '@hmn/rtl-web-core/components/Amp'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useVideoAdList } from '@hmn/rtl-web-core/context/ads/manifest.context'
// import { StructuredData } from '@hmn/rtl-web-core/components/StructuredData'
import { useVideoThumbnail } from '@hmn/rtl-web-core/hooks'

import { processAuthors } from '../../../../helpers/utils/processAuthors'
import { HtmlContent } from '../../../HtmlContent'
import { Meta } from '../../../Meta'
import { netTitleVariants, Title } from '../../../Title'
import { VideoContent } from '../../../Video/components'
import styles from './VideoHead.net.style'

const VideoHeadStyled = styled.div(props => ({ ...styles(props) }))

function VideoHead({
    className,
    title,
    subtitle,
    lead,
    author,
    authors = [],
    showAuthorInitials,
    date,
    videoElastic,
    rtlUrl,
    photoAuthor,
    categoryColor,
    authorSlug,
    label,
    labelLink,
    columnist,
    // This is a quick hacky fix untill app and app-ui get merged
    // eslint-disable-next-line react/prop-types
    SeoComponent,
    ...rest
}) {
    const isAmp = useAmp()

    const videoAds = useVideoAdList()
    const thumbnailUrl = useVideoThumbnail({ videoElastic })

    if (!videoElastic && !rtlUrl) {
        return null
    }

    return (
        <VideoHeadStyled {...rest}>
            <Title
                className="articleVideoHead_title"
                title={title}
                subtitle={subtitle}
                variant={netTitleVariants.ALPHA}
                categoryColor={categoryColor}
            />
            <div className="articleVideoHead_videoWrapper">
                {rtlUrl &&
                    (!videoElastic?.original_url || !videoElastic?.custom_manifest_url) &&
                    (isAmp ? (
                        <AmpIframe
                            src={rtlUrl?.replace('http://', 'https://')}
                            placeholder="/images/placeholder/net_logos.png"
                        />
                    ) : (
                        <iframe
                            className="articleVideoHead_videoIframe"
                            style={{ border: '0' }}
                            title={rtlUrl}
                            src={rtlUrl}
                            allow="autoplay"
                        />
                    ))}
                {(videoElastic?.original_url ||
                    videoElastic?.custom_manifest_url ||
                    videoElastic?.extended_attributes?.stream_url) && (
                    <VideoContent
                        ads={videoAds}
                        className="articleVideoHead_video"
                        id={videoElastic?.id}
                        inViewAutoplay
                        sticky
                        isHead
                        lead={videoElastic?.description}
                        subtitle={videoElastic?.extended_attributes?.subtitle}
                        thumbnail={thumbnailUrl}
                        title={videoElastic?.name}
                        url={
                            videoElastic?.extended_attributes?.stream_url ||
                            videoElastic?.original_url ||
                            videoElastic?.custom_manifest_url
                        }
                    />
                )}
            </div>
            <HtmlContent data={lead} className="articleVideoHead_lead" />
            {columnist && <div className="articleVideoHead_columnist">{columnist}</div>}
            <Meta
                className="articleVideoHead_meta"
                authorSlug={!columnist && authorSlug}
                author={!columnist && author}
                authors={!columnist && processAuthors(authors)}
                showAuthorInitials={showAuthorInitials}
                photoAuthor={photoAuthor}
                date={date}
            />
            {SeoComponent && <SeoComponent item={videoElastic} minorItem />}
        </VideoHeadStyled>
    )
}

const articleVideoHeadVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma'
})

VideoHead.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    labelLink: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    author: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    authors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired
            })
        )
    ]),
    columnist: PropTypes.node,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    photoAuthor: PropTypes.string,
    videoElastic: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    rtlUrl: PropTypes.string,
    categoryColor: PropTypes.string,
    authorSlug: PropTypes.string,
    showAuthorInitials: PropTypes.bool
}

VideoHead.defaultProps = {
    className: undefined,
    label: undefined,
    labelLink: undefined,
    title: undefined,
    subtitle: undefined,
    lead: undefined,
    author: undefined,
    authors: [],
    columnist: undefined,
    date: undefined,
    photoAuthor: undefined,
    videoElastic: undefined,
    rtlUrl: undefined,
    categoryColor: undefined,
    authorSlug: undefined,
    showAuthorInitials: false
}

export { articleVideoHeadVariants }
export default withErrorBoundary(VideoHead, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoHead]: ', error, componentStack)
    }
})
