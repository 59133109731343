// @TODO: ArticleShare - maybe the component will be removed
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { SocialShare } from '../../../Social/components'
import { ArticleTags } from '..'
import styles from './Meta.style'

const ArticleMetaStyled = styled.div(props => ({ ...styles(props) }))

function ArticleMeta({ tags, shareUrl, title, className, ...rest }) {
    const isAmp = useAmp()
    return (
        <ArticleMetaStyled className={className} {...rest}>
            {tags && <ArticleTags className="meta_tags" id="meta_tags" tags={tags} />}
            <SocialShare
                className="meta_social"
                title={title}
                social={[isAmp ? '' : 'facebook', 'twitterX', 'viber', 'whatsapp', 'url']}
                shareUrl={shareUrl}
                id="meta_social"
            />
        </ArticleMetaStyled>
    )
}

ArticleMeta.propTypes = {
    className: PropTypes.string,
    shareUrl: PropTypes.string,
    title: PropTypes.string,
    tags: PropTypes.oneOfType([() => null, PropTypes.object])
}

ArticleMeta.defaultProps = {
    className: undefined,
    shareUrl: undefined,
    title: undefined,
    tags: undefined
}

export default withErrorBoundary(ArticleMeta, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleMeta]: ', error, componentStack)
    }
})
