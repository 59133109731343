import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { HtmlContent } from '../../../HtmlContent'
import { ArrowNetIcon, Icon } from '../../../Icon'
import styles from './ImageDescription.net.style'

const ImageDescriptionStyled = styled.div(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ImageDescription({ className, description, ...rest }) {
    if (!description) {
        return null
    }
    return (
        <ImageDescriptionStyled {...rest}>
            <Icon className="description_icon" icon={ArrowNetIcon} viewBox="0 0 20 20" />
            <HtmlContent className="description_text" data={description} />
        </ImageDescriptionStyled>
    )
}

ImageDescription.propTypes = {
    className: PropTypes.string,
    description: PropTypes.string
}

ImageDescription.defaultProps = {
    className: undefined,
    description: undefined
}

export default withErrorBoundary(ImageDescription, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ImageDescription]: ', error, componentStack)
    }
})
