/* eslint-disable @nx/enforce-module-boundaries */
import React, { useState } from 'react' // Import React and useState hook
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary'
import Icon from '@hmn/rtl-web-core/src/components/Icon/Icon.component'

import ArrowUp from '@hmn/rtl-net-ui/components/Icon/icons/ArrowUp'

import AdSlot from '../../../Ad/NetSlot.component'
import InpictureStyled from './Inpicture.style'

export function Inpicture({ inPictureAdConfig }) {
    const [isVisible, setIsVisible] = useState(true)

    const handleClose = () => {
        setIsVisible(false)
    }

    // Do not render if the component is not visible
    if (!isVisible) {
        return null
    }

    return (
        <InpictureStyled className="inpicture">
            <Icon className="inpicture__close" icon={ArrowUp} size={20} onClick={handleClose} />
            <AdSlot className="inpicture__ad" {...inPictureAdConfig} />
        </InpictureStyled>
    )
}

Inpicture.propTypes = {
    inPictureAdConfig: PropTypes.shape({
        id: PropTypes.string,
        size: PropTypes.string,
        targeting: PropTypes.shape({
            pos: PropTypes.string
        })
    })
}

Inpicture.defaultProps = {
    inPictureAdConfig: {}
}
export default withErrorBoundary(Inpicture, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Inpicture]: ', error, componentStack)
    }
})
