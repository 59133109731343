import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant }) => {
    const config = {
        sm: {
            pollGraphics: {
                left: 0
            },
            variant: {
                beta: {
                    container: {
                        height: toRem(212)
                    }
                }
            },
            width: '100%'
        },
        md: {
            pollGraphics: {
                left: toRem(-15)
            },
            variant: {
                beta: {
                    padding: toRems([38, 0, 35, 50]),
                    container: {
                        height: toRem(275)
                    }
                }
            },
            header: {
                variant: {
                    beta: { paddingRight: toRem(40) }
                }
            }
        }
    }
    return {
        display: 'flex',
        position: 'relative',
        width: config?.sm?.width,
        [theme.breakpoints.up('md')]: {
            width: '100%'
        },
        padding: config?.md?.variant[variant]?.padding || toRems([38, 40, 55, 50]),
        background: theme.colors.poll.background,
        '& .poll': {
            width: '100%',
            '&_subtitle': {
                fontSize: toRem(16),
                lineHeight: 1.2,
                fontWeight: 600,
                color: theme.colors.poll.color
            },
            '&_title': {
                fontSize: toRem(24),
                lineHeight: 1.2,
                marginBottom: toRem(20)
            },
            '&_items': {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                scrollbarWidth: 0,
                '&::-webkit-scrollbar': {
                    width: 0
                }
            },
            '& .swiper': {
                height: config?.sm?.variant[variant]?.container?.height,
                marginBottom: toRem(10),
                [theme.breakpoints.up('md')]: {
                    marginBottom: toRem(20),
                    height: config?.md?.variant[variant]?.container?.height
                }
            },
            '& .swiper-slide': {
                height: '100%',
                width: '45%',
                [theme.breakpoints.up('md')]: {
                    width: '30%'
                }
            },
            '&_footer': {
                display: 'flex',
                justifyContent: 'space-between',
                paddingRight: config?.md?.header?.variant[variant]?.paddingRight,
                '&_slider': {
                    height: '30%',
                    '&_button': {
                        borderRadius: '50%',
                        backgroundColor: '#EAEAEA',
                        border: 'none',
                        width: toRem(42),
                        minHeight: toRem(42),
                        height: toRem(42),
                        marginLeft: toRem(10),
                        '& svg': {
                            width: toRem(14),
                            height: toRem(14)
                        }
                    }
                },
                '&_submit': {
                    height: toRem(50),
                    '& svg': {
                        width: toRem(16),
                        height: toRem(16)
                    }
                },
                '&_total': {
                    fontSize: toRem(16),
                    lineHeight: 1.4,
                    marginTop: toRem(13),
                    '&_votes': {
                        fontWeight: theme.typography.fontWeight.bold
                    }
                }
            },
            '&_decoration': {
                position: 'absolute',
                bottom: 0,
                left: 0
            }
        }
    }
}

export default style
