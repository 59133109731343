export const articleCardContentMarkerVariants = Object.freeze({
    NONE: 'NONE',
    ARTICLE: 'ARTICLE',
    GALLERY: 'GALLERY',
    VIDEO: 'VIDEO',
    USER: 'USER'
})

export const articleCardContentMarkerSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
})

export const articleCardVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta',
    EPSILON: 'epsilon'
})

export const articleCardSizeVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '1x3': '1x3',
    '2x3': '2x3',
    '1x4': '1x4',
    '1x5': '1x5',
    '1x6': '1x6'
})
export const articleCardContentMarkerVariant = type => {
    switch (type.toUpperCase()) {
        case 'ARTICLE':
            return articleCardContentMarkerVariants.ARTICLE
        case 'GALLERY':
            return articleCardContentMarkerVariants.GALLERY
        case 'VIDEO':
            return articleCardContentMarkerVariants.VIDEO
        default:
            return articleCardContentMarkerVariants.NONE
    }
}
