import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints, usePoll } from '@hmn/rtl-web-core/hooks'

import { Button, netButtonVariants } from '../../../Button'
import { Graphic, netGraphicFillVariants, netGraphicVariants } from '../../../Decoration/components'
import { ArrowLeftIcon, Icon, UnionIcon } from '../../../Icon'
import { ArrowRightPollIcon } from '../../../Icon/icons/poll'
import { PollItem } from './Item'
import styles from './Poll.style'

const getCookieName = id => `poll-${id}`

SwiperCore.use([Navigation])
const ArticlePollStyled = styled.div(props => ({ ...styles(props) }))

function ArticlePoll({ id, className, variant, ...rest }) {
    const {
        getValues,
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { isSubmitting, isSubmitSuccessful }
    } = useForm()
    const { data: pollItem, addVote } = usePoll({ id })

    const swiperComponent = useRef()
    const uid = useUIDSeed()
    const [isDesktop] = useBreakpoints('md')
    const [hasBeenSubmited, setHasBeenSubmited] = useState(false)

    useEffect(() => {
        const cookies = window.document?.cookie?.split(';').reduce((all, cookie) => {
            const [key, ...vals] = cookie.trim().split('=')
            all[key] = vals.join('=')
            return all
        }, {})

        setHasBeenSubmited(!!cookies[getCookieName(id)])
    })

    const poll = useMemo(() => {
        if (pollItem) {
            let totalVotes = 0

            if (pollItem.extended_attributes?.poll_answers) {
                totalVotes = pollItem.extended_attributes.poll_answers.reduce(
                    (acc, current) => acc + (current?.votes || 0),
                    0
                )
            }

            return {
                id: pollItem.id,
                title: pollItem.title,
                allowAnonymous: pollItem.extended_attributes?.poll_allow_anonymous,
                answers: pollItem.extended_attributes?.poll_answers,
                totalVotes
            }
        }
        return {}
    }, [pollItem])

    // const validateForm = values => {
    //     const errors = {}

    //     if (Number.isNaN(parseInt(values.poll, 10))) {
    //         errors.poll = 'Required'
    //     }

    //     return errors
    // }

    const onSubmit = useCallback(async () => {
        const date = new Date()
        // @NOTE: VOTE ONCE A DAY
        date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0)
        document.cookie = `${getCookieName(poll.id)}=1;expires=${date.toGMTString()}`

        const index = poll?.answers?.map(item => item?.answer)?.indexOf(getValues('answer'))
        // add user answer to result and increase total votes (untill refresh)
        poll.answers[index].votes += 1
        poll.totalVotes += 1

        await addVote(index)
        setHasBeenSubmited(true)
    }, [poll])

    useEffect(() => {
        watch()
    }, [])

    const pollVariant = useMemo(() => {
        if (variant) {
            return variant
        }

        const pollType = pollItem?.extended_attributes?.poll_type

        if (pollType === 'regular') {
            return 'alpha'
        }

        return 'beta'
    }, [variant, pollItem])

    const showAnswers = hasBeenSubmited || isSubmitSuccessful || !poll.allowAnonymous

    if (!pollItem?.extended_attributes || !pollItem?.extended_attributes?.published) {
        return null
    }

    const slideLeft = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slidePrev()
        }
    }

    const slideRight = () => {
        const swiper = swiperComponent?.current?.swiper
        if (swiper) {
            swiper.slideNext()
        }
    }

    return (
        <ArticlePollStyled className={className} variant={pollVariant} {...rest}>
            <form onSubmit={handleSubmit(onSubmit)} className="poll" target="_top">
                <span className="poll_subtitle">Anketa</span>
                <h3 className="poll_title">{poll?.title}</h3>
                {!['beta'].includes(pollVariant) && (
                    <div className="poll_items">
                        {poll?.answers?.map((item, index) => {
                            if (!item.answer && !item.image) {
                                return null
                            }
                            const percentage =
                                showAnswers && poll?.totalVotes
                                    ? ((100 * parseInt(item.votes, 10)) / (poll?.totalVotes || 0) || 0).toFixed()
                                    : null
                            return (
                                <PollItem
                                    key={uid(item?.answer || index)}
                                    title={item.answer}
                                    image={item.image}
                                    pollVariant={pollVariant}
                                    radioGroup="poll"
                                    name={index}
                                    onClick={() => setValue('answer', item?.answer)}
                                    isSelected={getValues('answer') === item?.answer}
                                    // setFieldValue={showAnswers ? () => {} : setFieldValue}
                                    votePercentage={percentage}
                                />
                            )
                        })}
                    </div>
                )}
                {['beta'].includes(pollVariant) && (
                    <Swiper
                        ref={swiperComponent}
                        className="poll_swiper"
                        spaceBetween={isDesktop ? 10 : 5}
                        slidesPerView="auto">
                        {poll?.answers?.map((item, index) => {
                            if (!item.answer && !item.image) {
                                return null
                            }

                            const percentage =
                                showAnswers && poll?.totalVotes
                                    ? ((100 * item.votes) / (poll?.totalVotes || 0) || 0).toFixed()
                                    : null

                            return (
                                <SwiperSlide key={uid(item?.answer || index)} className="poll_swiper_slide">
                                    <PollItem
                                        title={item.answer}
                                        image={item.image}
                                        pollVariant={pollVariant}
                                        radioGroup="poll"
                                        name={index}
                                        onClick={() => setValue('answer', item?.answer)}
                                        isSelected={getValues('answer') === item?.answer}
                                        votePercentage={percentage}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                )}
                <div className="poll_footer">
                    {!showAnswers ? (
                        <Button
                            type="submit"
                            title="Odgovori"
                            className="poll_footer_submit"
                            // disabled={isSubmitting}
                            loading={isSubmitting}
                            iconComponent={<Icon icon={UnionIcon} />}
                            variant={netButtonVariants.BETA}>
                            Odgovori
                        </Button>
                    ) : (
                        <div className="poll_footer_total">
                            Ukupno odgovora <span className="poll_footer_total_votes">{poll?.totalVotes}</span>
                        </div>
                    )}
                    {poll?.answers?.length > (isDesktop ? 3 : 2) && ['beta'].includes(pollVariant) && (
                        <div className="poll_footer_slider">
                            <Button
                                className="poll_footer_slider_button"
                                onClick={slideLeft}
                                iconComponent={<Icon icon={ArrowLeftIcon} size={10} />}
                            />
                            <Button
                                className="poll_footer_slider_button"
                                onClick={slideRight}
                                iconComponent={<Icon icon={ArrowRightPollIcon} size={14} />}
                            />
                        </div>
                    )}
                </div>
                <input style={{ visibility: 'hidden' }} {...register('answer')} />
            </form>
            <Graphic
                className="poll_decoration"
                variant={netGraphicVariants.ALPHA}
                fillVariant={netGraphicFillVariants.ALTERNATIVE}
                heightMd={10}
                heightXs={10}
                widthMd="100%"
                widthXs="100%"
            />
            {/* <ProfilePopup profileModalUid="profile-modal" /> */}
        </ArticlePollStyled>
    )
}

export const articlePollVariants = {
    ALPHA: 'alpha',
    BETA: 'beta'
}

ArticlePoll.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articlePollVariants)]),
    id: PropTypes.string
}

ArticlePoll.defaultProps = {
    className: undefined,
    variant: undefined,
    id: undefined
}

export default withErrorBoundary(ArticlePoll, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticlePoll]: ', error, componentStack)
    }
})
