import styled from '@emotion/styled'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { AntiClsFrame } from '@hmn/rtl-web-core/components/AntiClsFrame'
import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { videoAdsPropType } from '@hmn/rtl-web-core/components/VideoJsPlayer/VideoJsPlayer.component'

import { HtmlContent } from '../../../HtmlContent'
import { Title } from '../../../Title'
import styles from './Content.style'

const VideoJsPlayer = dynamic(() => import('./VideoJs.player').then(mod => mod.VideoJsPlayer), { ssr: false })

const ContentStyled = styled.section(props => ({ ...styles(props) }))

function Content({
    ads,
    autoplay,
    className,
    id,
    inViewAutoplay,
    isEmbed,
    isFullscreen,
    isHead,
    lead,
    sticky,
    subtitle,
    thumbnail,
    title,
    url,
    ...rest
}) {
    return (
        <ContentStyled className={className} isEmbed={isEmbed} isHead={isHead} isFullscreen={isFullscreen} {...rest}>
            <div className="videoContent_wrapper">
                <AntiClsFrame ratio={0.56} width="100%">
                    <div className="videoContent_playerContainer">
                        <VideoJsPlayer
                            className={clsx(
                                'videoContent_videoJsPlayer',
                                isFullscreen && 'videoContent_playerFullscreen'
                            )}
                            url={url}
                            ads={ads}
                            id={id}
                            isEmbed={isEmbed}
                            sticky={!isFullscreen && sticky}
                            isHead={isHead}
                            thumbnail={thumbnail}
                            autoplay={autoplay}
                            fill={isFullscreen}
                            fluid={!isFullscreen}
                            inViewAutoplay={inViewAutoplay}
                        />
                    </div>
                </AntiClsFrame>
                {!isHead && !isEmbed && (
                    <div className="videoContent_head">
                        <Title title={title} subtitle={subtitle} titleTag="h1" />
                        {lead && <HtmlContent data={lead} className="videoContent_lead" />}
                    </div>
                )}
            </div>
        </ContentStyled>
    )
}

Content.propTypes = {
    ads: videoAdsPropType,
    autoplay: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    inViewAutoplay: PropTypes.bool,
    isEmbed: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    isHead: PropTypes.bool,
    lead: PropTypes.string,
    sticky: PropTypes.bool,
    subtitle: PropTypes.string,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    title: PropTypes.string,
    url: PropTypes.string
}

Content.defaultProps = {
    ads: [],
    autoplay: false,
    className: undefined,
    id: undefined,
    inViewAutoplay: false,
    isEmbed: false,
    isFullscreen: false,
    isHead: false,
    lead: undefined,
    sticky: false,
    subtitle: undefined,
    thumbnail: undefined,
    title: undefined,
    url: undefined
}

export default withErrorBoundary(Content, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoContent]: ', error, componentStack)
    }
})
