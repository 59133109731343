import { toRem } from '../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        sm: {
            display: 'flex',
            flexDirection: 'column',
            meta: {
                marginBottom: toRem(12)
            }
        },
        md: {
            display: 'flex',
            flexDirection: 'row',
            meta: {
                marginBottom: 0
            }
        }
    }

    return {
        '.meta_info': {
            display: config.sm.display,
            flexDirection: config.sm.flexDirection,
            [theme.breakpoints.up('md')]: {
                display: config.md.display,
                flexDirection: config.md.flexDirection,
                justifyContent: 'space-between'
            },
            '&_items': {
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                marginRight: toRem(9),
                marginBottom: config.sm.meta.marginBottom,
                [theme.breakpoints.up('md')]: {
                    marginBottom: config.md.meta.marginBottom
                }
            }
        },
        '.meta_tags': {
            marginTop: toRem(22),
            [theme.breakpoints.up('md')]: {
                marginTop: toRem(27)
            }
        }
    }
}

export default style
