/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Newsletter = createSvgIcon(
    <path
        d="M38.323 12.555l-5.05-10.83a2.985 2.985 0 00-3.97-1.444L11.257 8.697a2.985 2.985 0 00-1.445 3.97l5.05 10.828a2.985 2.985 0 003.97 1.445l18.047-8.416a2.985 2.985 0 001.445-3.97zm-6.68-9.614l4.7 10.081-7.39-2.69zM16.49 22.28l-4.7-10.081 7.39 2.69zM12.47 10.334l17.309-8.072-4.276 11.749a1.008 1.008 0 01-1.285.599zm5.887 12.625l2.69-7.391 2.492.907a2.99 2.99 0 003.829-1.786l.907-2.492 7.391 2.69zM9.66 17.132a.996.996 0 00-1.324-.482l-7.76 3.62a.996.996 0 10.84 1.804l7.762-3.62a.996.996 0 00.481-1.322zM7.976 13.522a.995.995 0 00-1.323-.481l-4.151 1.935a.996.996 0 10.841 1.805l4.151-1.936a.996.996 0 00.482-1.323zM11.342 20.741a.996.996 0 00-1.323-.481l-7.76 3.619a.996.996 0 10.84 1.804l7.762-3.619a.996.996 0 00.481-1.323zM13.026 24.35a.996.996 0 00-1.324-.48l-4.15 1.935a.996.996 0 10.84 1.805l4.152-1.936a.996.996 0 00.482-1.323z"
        fill="#080808"
    />,
    'Newsletter'
)

export default Newsletter
