/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const InstagramHollowNet = createSvgIcon(
    <>
        <path
            d="M12.75 1.5H5.25C3.17893 1.5 1.5 3.17893 1.5 5.25V12.75C1.5 14.8211 3.17893 16.5 5.25 16.5H12.75C14.8211 16.5 16.5 14.8211 16.5 12.75V5.25C16.5 3.17893 14.8211 1.5 12.75 1.5Z"
            stroke="#080808"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12.0008 8.52773C12.0933 9.15191 11.9867 9.78939 11.6961 10.3495C11.4055 10.9096 10.9456 11.3638 10.382 11.6475C9.81834 11.9312 9.17959 12.0299 8.5566 11.9297C7.9336 11.8294 7.35808 11.5353 6.91189 11.0891C6.46569 10.6429 6.17156 10.0674 6.07131 9.44439C5.97106 8.82139 6.06981 8.18265 6.3535 7.61901C6.6372 7.05537 7.0914 6.59553 7.6515 6.30491C8.21159 6.01428 8.84907 5.90767 9.47326 6.00023C10.1099 6.09464 10.6994 6.39132 11.1545 6.84646C11.6097 7.30159 11.9063 7.89103 12.0008 8.52773Z"
            stroke="#080808"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M13.125 4.875H13.1325" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </>,
    'InstagramHollowNet'
)

export default InstagramHollowNet
