import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { Link } from '../../../Link'
import styles from './Tags.style'

const TagsStyled = styled.section(props => ({ ...styles(props) }))

function Tags({ className, tags, tagBasePath, ...rest }) {
    const uid = useUIDSeed()

    if (!tags?.length) {
        return null
    }

    return (
        <TagsStyled className={className} {...rest}>
            <div className="tagsSectionContent" id="tags_section_content">
                {tags?.map(item => (
                    <Link
                        key={uid(item)}
                        href={`/${tagBasePath}/[id]`}
                        as={`/${tagBasePath}/${item?.taxon?.slug}`}
                        title={item?.taxon?.title}
                        id={`tag_item_${item?.taxon?.slug}`}
                        className="tagItem">
                        {item?.taxon?.title}
                    </Link>
                ))}
            </div>
        </TagsStyled>
    )
}

Tags.propTypes = {
    className: PropTypes.string,
    tags: PropTypes.oneOfType([() => null, PropTypes.object]),
    tagBasePath: PropTypes.string
}

Tags.defaultProps = {
    className: undefined,
    tags: undefined,
    tagBasePath: 'tema'
}

export default Tags
