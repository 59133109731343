// @TODO: revisit once attributes are migrated

import getConfig from 'next/config'

import { toRem, toRems } from '../../helpers/theme'

const { publicRuntimeConfig = {} } = getConfig()
const { assetPrefix } = publicRuntimeConfig

const largeTitleMarginTopXs = 35 // h1, h2, h3, h4
const largeTitleMarginTopMd = 42

const smallTitleMarginTopXs = 20 // h5, h6

const style = ({ theme, endDecoration }) => ({
    width: '100%',
    '& > * + h1': {
        marginTop: toRem(largeTitleMarginTopXs),
        marginBottom: toRem(11),
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(largeTitleMarginTopMd),
            marginBottom: toRem(21)
        }
    },
    '& > * + h2': {
        marginTop: toRem(largeTitleMarginTopXs),
        marginBottom: toRem(11),
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(largeTitleMarginTopMd),
            marginBottom: toRem(21)
        }
    },
    '& > * + h3': {
        marginTop: toRem(largeTitleMarginTopXs),
        marginBottom: toRem(11),
        [theme.breakpoints.up('md')]: {
            marginTop: toRem(largeTitleMarginTopMd),
            marginBottom: toRem(21)
        }
    },
    h4: {
        marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
        lineHeight: 1.6,

        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin),
            lineHeight: 1.7
        }
    },
    '& > * + h4': {
        marginTop: toRem(largeTitleMarginTopXs - 3),
        marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),

        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin)
        }
    },
    '& h5, h6': {
        margin: toRems([0, 0, 4, 0])
    },
    '& > * + h5, > * + h6': {
        marginTop: toRem(smallTitleMarginTopXs)
    },

    '& > .marginCollapse +': {
        'h1, h2, h3, h4': {
            marginTop: toRem(largeTitleMarginTopXs - theme.global.htmlContent.xs.blockVerticalMargin),

            [theme.breakpoints.up('md')]: {
                marginTop: toRem(largeTitleMarginTopMd - theme.global.htmlContent.md.blockVerticalMargin)
            }
        },
        'h5, h6': {
            marginTop: toRem(smallTitleMarginTopXs - theme.global.htmlContent.xs.blockVerticalMargin),

            [theme.breakpoints.up('md')]: {
                marginTop: toRem(smallTitleMarginTopXs - theme.global.htmlContent.md.blockVerticalMargin)
            }
        }
    },
    img: {
        width: '100%',
        maxWidth: '100%',
        height: 'auto'
    },
    '& .Image-noPlaceholder.Image-isLoading': {
        display: 'none'
    },
    p: {
        marginBottom: toRem(theme.global.htmlContent.xs.blockVerticalMargin),
        lineHeight: 1.6,
        position: 'relative',
        ...(endDecoration && {
            '&:last-of-type': {
                '&::after': {
                    content: '""',
                    display: 'inline-block',
                    verticalAlign: 'sub',
                    marginLeft: toRem(5),
                    height: toRem(25),
                    width: toRem(25),
                    background: `url(${assetPrefix || ''}/svg/icons/rose.svg)`,
                    backgroundSize: 'contain'
                }
            }
        }),

        [theme.breakpoints.up('md')]: {
            marginBottom: toRem(theme.global.htmlContent.md.blockVerticalMargin),
            lineHeight: 1.7
        }
    },

    a: {
        color: theme.colors.link.alpha.text.default,
        textDecoration: 'underline',
        '&:visited': {
            color: theme.colors.link.alpha.text.visited,
            borderBottom: 'none',
            textDecoration: 'underline'
        },
        '&:hover': {
            color: theme.colors.link.alpha.text.hover,
            textDecoration: 'none'
        },
        '&:active': {
            color: theme.colors.link.alpha.text.active,
            textDecoration: 'none'
        }
    },
    ol: {
        margin: toRems([theme.global.htmlContent.xs.blockVerticalMargin * 1.5, 0]),
        counterReset: 'ol-list-counter',
        [theme.breakpoints.up('md')]: {
            margin: toRems([theme.global.htmlContent.md.blockVerticalMargin * 1.8, 0])
        },
        '> li': {
            counterIncrement: 'ol-list-counter',
            margin: toRems([0, 0, 18, 0]),
            padding: toRems([0, 0, 0, 25]),
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 26, 0]),
                padding: toRems([0, 0, 0, 30])
            },
            '&::before': {
                content: 'counter(ol-list-counter) "."',
                display: 'block',
                position: 'relative',
                maxWidth: 0,
                maxHeight: 0,
                left: toRem(-25),
                color: theme.colors.text.default,
                [theme.breakpoints.up('md')]: {
                    left: toRem(-30)
                }
            },
            ul: {
                li: {
                    '&::before': {
                        content: '""'
                    }
                }
            },
            'ul, ol': {
                flexBasis: '100%',
                counterReset: 'ol-list-counter'
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        }
    },
    ul: {
        margin: toRems([theme.global.htmlContent.xs.blockVerticalMargin * 1.5, 0]),
        [theme.breakpoints.up('md')]: {
            margin: toRems([theme.global.htmlContent.md.blockVerticalMargin * 1.8, 0])
        },
        '> li': {
            margin: toRems([0, 0, 18, 0]),
            padding: toRems([0, 0, 0, 25]),
            position: 'relative',
            [theme.breakpoints.up('md')]: {
                margin: toRems([0, 0, 26, 0]),
                padding: toRems([0, 0, 0, 30])
            },
            '&::before': {
                content: '""',
                position: 'absolute',
                top: toRem(12),
                left: 0,
                width: toRem(15),
                height: 2,
                background: theme.colors.text.default,
                [theme.breakpoints.up('md')]: {
                    top: toRem(16),
                    width: toRem(20)
                }
            },
            '&:last-of-type': {
                marginBottom: 0
            }
        }
    },
    table: {
        width: '100%',
        fontSize: toRem(18),
        padding: toRems([0, 30]),
        marginBottom: toRem(50),
        [theme.breakpoints.up('md')]: {
            border: `1px solid ${theme.colors.border.light}`
        },
        borderCollapse: 'collapse',
        tr: {
            borderBottom: `1px solid ${theme.colors.border.light}`,
            '&:first-of-type': {
                borderBottom: `3px solid ${theme.colors.border.light}`
            },
            '&:last-of-type': {
                borderBottom: 'none'
            }
        }
    },
    th: {
        padding: toRem(10),
        textAlign: 'center',
        color: theme.colors.text.alternative
    },
    td: {
        padding: toRem(10),
        textAlign: 'center',
        minWidth: toRem(150)
    },
    tf: {
        padding: toRem(10),
        textAlign: 'center'
    },

    // politika  kolačića
    '.custom_table & table': {
        fontSize: toRem(16),
        tr: {
            borderColor: '#d8d8d8',
            ':nth-last-of-type(-n + 31)': {
                display: 'none'
            }
        },
        td: {
            padding: '5px',
            textAlign: 'left',

            minWidth: '80px',
            maxWidth: '120px',
            wordWrap: 'break-word',
            verticalAlign: 'top',

            '&:first-of-type': {
                minWidth: '100px',
                maxWidth: '200px',
                textWrap: 'wrap'
            },

            '&:last-of-type': {
                minWidth: '300px',
                maxWidth: '300px'
            }
        }
    }
})

export default style
