export { default as WhatsAppNetIcon } from './WhatsAppNet'
export { default as FacebookNetIcon } from './FacebookNet'
export { default as TwitterXNetIcon } from './TwitterXNet'
export { default as ViberNetIcon } from './ViberNet'
export { default as ShareNetIcon } from './ShareNet'
export { default as YoutubeNetIcon } from './YoutubeNet'
export { default as TiktokNetIcon } from './TiktokNet'

export { default as FacebookHollowNetIcon } from './FacebookHollowNet'
export { default as InstagramHollowNetIcon } from './InstagramHollowNet'
export { default as MailHollowNetIcon } from './MailHollowNet'
export { default as XHollowNetIcon } from './XHollowNet'
