import { toRem, toRems } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, isHunk }) => ({
    ...(isHunk && {
        [theme.breakpoints.down('md')]: {
            paddingBottom: toRem(15),
            marginTop: toRem(-25)
        }
    }),

    '& .tagsSectionContent': {
        marginBottom: toRem(-10),

        '& .tagItem': {
            display: 'inline-flex',
            verticalAlign: 'middle',
            minHeight: toRem(28),
            padding: toRems([5, 8]),
            margin: toRems([0, 10, 10, 0]),
            border: `1px solid ${theme.colors.pill.border.default}`,
            backgroundColor: theme.colors.pill.background.default,
            fontSize: toRem(14),
            lineHeight: 1.2,
            wordBreak: 'break-word',
            color: '#666766',
            transitionDuration: theme.transitions.pill.default.duration,
            transitionTimingFunction: theme.transitions.pill.default.timingFunction,
            transitionDelay: theme.transitions.pill.default.delay,
            transitionProperty: 'color, border-color',

            '&:hover': {
                borderColor: theme.colors.pill.text.hover,
                color: theme.colors.pill.text.hover
            }
        }
    }
})

export default style
