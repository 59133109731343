import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, variant, isAmp }) => {
    const config = {
        sm: {
            title: {
                marginBottom: toRem(11),
                padding: toRems([0, 15, 0, 15])
            },
            lead: {
                marginTop: toRem(10),
                marginBottom: toRem(10),
                fontSize: toRem(20),
                padding: toRems([0, 15, 0, 15])
            },
            meta: {
                padding: toRems([0, 15, 0, 15])
            },
            gamma: {
                meta: {
                    marginTop: toRem(11)
                }
            }
        },
        md: {
            title: {
                marginBottom: toRem(35),
                padding: 0
            },
            lead: {
                marginTop: toRem(21),
                marginBottom: toRem(18),
                fontSize: toRem(30),
                padding: 0
            },
            meta: {
                padding: 0
            },
            gamma: {
                meta: {
                    marginTop: toRem(21)
                }
            }
        }
    }

    return {
        width: '100%',
        marginBottom: toRem(30),
        ...(!isAmp && {
            [theme.breakpoints.up('md')]: {
                marginBottom: toRem(44)
            }
        }),
        '.articleVideoHead': {
            '&_title': {
                padding: config.sm.title.padding,
                marginBottom: config.sm.title.marginBottom,

                ...(!isAmp && {
                    [theme.breakpoints.up('md')]: {
                        padding: config.md.title.padding,
                        marginBottom: config.md.title.marginBottom
                    }
                })
            },
            '&_videoWrapper': {
                marginBottom: toRem(10)
            },
            ...(!isAmp && {
                '&_videoIframe': {
                    width: '100%',
                    height: toRem(215),
                    [theme.breakpoints.up('md')]: {
                        height: toRem(423)
                    }
                },
                '&_lead': {
                    marginBottom: config.sm.lead.marginBottom,
                    padding: config.sm.lead.padding,
                    [theme.breakpoints.up('md')]: {
                        marginBottom: config.md.lead.marginBottom,
                        padding: config.md.lead.padding
                    }
                },
                '&_lead > p': {
                    lineHeight: 1.4,
                    letterSpacing: '-0.03em',
                    fontSize: config.sm.lead.fontSize,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.lead.fontSize
                    }
                },
                '&_meta': {
                    padding: config.sm.lead.padding,
                    marginTop: config.sm[variant]?.meta?.marginTop,
                    [theme.breakpoints.up('md')]: {
                        marginTop: config.md[variant]?.meta?.marginTop,
                        padding: config.md.lead.padding
                    }
                }
            })
        }
    }
}

export default style
