/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const FacebookHollowNet = createSvgIcon(
    <path
        d="M9.5 1.5H7.25C6.25544 1.5 5.30161 1.89509 4.59835 2.59835C3.89509 3.30161 3.5 4.25544 3.5 5.25V7.5H1.25V10.5H3.5V16.5H6.5V10.5H8.75L9.5 7.5H6.5V5.25C6.5 5.05109 6.57902 4.86032 6.71967 4.71967C6.86032 4.57902 7.05109 4.5 7.25 4.5H9.5V1.5Z"
        stroke="#080808"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    />,
    'FacebookHollowNet'
)

export default FacebookHollowNet
