import { useMemo } from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useIsDanasCategory } from '@hmn/rtl-web-core/hooks/useIsDanasCategory'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { FacebookNetIcon, Icon, ShareNetIcon, TwitterXNetIcon, ViberNetIcon, WhatsAppNetIcon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './Item.style'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

const getIconFromUrl = url => {
    if (!url) {
        return null
    }
    if (url.includes('twitter.com')) {
        return TwitterXNetIcon
    }
    if (url.includes('facebook.com')) {
        return FacebookNetIcon
    }
    return null
}

const getIconFromName = name => {
    switch (name.toString().toLowerCase()) {
        case 'twitterX':
            return TwitterXNetIcon
        case 'facebook':
            return FacebookNetIcon
        case 'whatsapp':
            return WhatsAppNetIcon
        case 'viber':
            return ViberNetIcon
        case 'url':
            return ShareNetIcon
        default:
            return null
    }
}

// @TODO: handle button title, most probably needed as a prop or just do something general based on social network

function Item({ icon, href, hrefIsExternal, onClick, ...rest }) {
    const IconComponent = getIconFromName(icon) || getIconFromUrl(href)
    const hasOnClick = typeof onClick === 'function'
    const isDanasCategory = useIsDanasCategory()

    const title = useMemo(() => {
        if (!icon) {
            return ''
        }
        if (icon === 'url') {
            return 'Kopiraj link'
        }
        return `${icon.charAt(0).toUpperCase() + icon.slice(1)}`
    }, [icon])

    if (!IconComponent || (!href && !hasOnClick)) {
        return null
    }
    return (
        <ItemStyled {...rest} isDanas={isDanasCategory}>
            <ConditionalWrapper
                condition={!!href && !hasOnClick}
                Wrapper={Link}
                title={title}
                href={href}
                isExternal={hrefIsExternal}>
                <ConditionalWrapper
                    condition={hasOnClick}
                    Wrapper="button"
                    title={title}
                    aria-label={title}
                    type="button"
                    onClick={onClick}>
                    <Icon icon={IconComponent} />
                </ConditionalWrapper>
            </ConditionalWrapper>
        </ItemStyled>
    )
}

Item.propTypes = {
    icon: PropTypes.oneOf(['facebook', 'instagram', 'tiktok', 'twitterX', 'viber', 'whatsapp', 'url', 'messenger', '']),
    href: PropTypes.string,
    hrefIsExternal: PropTypes.bool,
    onClick: PropTypes.func
}

Item.defaultProps = {
    icon: undefined,
    href: undefined,
    hrefIsExternal: false,
    onClick: undefined
}

export default withErrorBoundary(Item, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[SocialItem]: ', error, componentStack)
    }
})
