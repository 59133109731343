import { toRem } from '../../../../helpers/theme'

const style = () => ({
    marginRight: toRem(5),
    whiteSpace: 'nowrap',
    '& .socialShare': {
        '&_item': {
            display: 'inline',
            marginRight: toRem(5)
        }
    }
})

export default style
