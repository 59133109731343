import React from 'react'
import dynamic from 'next/dynamic'

import { CommonSofascoreEmbed } from '@hmn/rtl-web-core/components/HtmlContent'
import { EmbedVideo } from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.style'

import { FacebookEmbed } from '../FacebookItem/FacebookItem.component'

const ArticleImageDescription = dynamic(() =>
    import('../Article/components/ImageDescription/ImageDescription.net.component')
)

function Rtl(attributes) {
    const { url, type, description, ...attrs } = attributes
    // @TODO: revisit if type can be image
    if (type === 'video') {
        return (
            <EmbedVideo {...attrs}>
                {/* eslint-disable-next-line react/no-unknown-property */}
                <iframe allowFullScreen style={{ border: '0' }} title={url} src={url} />

                <ArticleImageDescription description={description} />
            </EmbedVideo>
        )
    }
    return null
}

const embeds = new Map()
embeds.set('rtl', attributes => <Rtl {...attributes} />)
embeds.set('facebook', attributes => <FacebookEmbed {...attributes} />)
embeds.set('sofascore', attributes => (
    <CommonSofascoreEmbed {...attributes} placeholder="/images/placeholder/net_logos.png" />
))
export { embeds }
