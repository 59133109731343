export function processAuthors(authors) {
    if (typeof authors === 'string') {
        return [{ title: authors }]
    }
    if (Array.isArray(authors)) {
        return authors
    }
    if (typeof authors === 'object' && authors !== null) {
        return [authors]
    }
    return []
}
