import { toRem } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        sm: {
            title: {
                fontSize: toRem(12)
            }
        },
        md: {
            title: {
                fontSize: toRem(13)
            }
        }
    }

    return {
        display: 'flex',
        marginRight: toRem(15),
        '.metaItem': {
            display: 'flex',
            '&_title': {
                textTransform: 'uppercase',
                color: theme.colors.text.accent,
                fontWeight: 900,
                lineHeight: 1.2,
                marginLeft: toRem(5),
                minHeight: '1.3em',
                fontSize: config.sm.title.fontSize,
                [theme.breakpoints.up('md')]: {
                    fontSize: config.md.title.fontSize
                }
            },
            '&_icon': {
                width: toRem(13),
                height: toRem(13)
            },
            '&_icon.author': {
                position: 'relative',
                marginTop: toRem(2),
                width: toRem(13),
                height: toRem(13)
            },

            '&_separator': {
                color: '#666766'
            }
        }
    }
}

export default style
