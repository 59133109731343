import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => ({
    display: 'inline',
    // marginRight: toRem(5),
    position: 'relative',
    '& .shareButton': {
        '&_popUp': {
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: toRem(12),
            padding: toRems([5, 8]),
            color: theme.colors.social?.popUp?.text,
            background: theme.colors.social?.popUp?.background,
            '&_wrapper': {
                marginTop: toRem(-42),
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                left: 0,
                top: 0,
                width: '100%'
            }
        }
    }
})

export default style
