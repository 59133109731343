import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Icon, PhotoIcon, VideoIcon } from '../Icon'
import styles from './ContentMarker.style'

const ContentMarkerStyled = styled.button(props => ({ ...styles(props) }))

function ContentMarker({ className, sizeVariant, variant, categoryColor, tiny, ...rest }) {
    return (
        <ContentMarkerStyled
            className={className}
            sizeVariant={sizeVariant}
            variant={variant}
            categoryColor={categoryColor}
            tiny={tiny}
            aria-label={variant === 'GALLERY' ? 'Galerija' : 'Video'}
            {...rest}>
            <Icon icon={variant === 'GALLERY' ? PhotoIcon : VideoIcon} viewBox="0 0 64 64" />
        </ContentMarkerStyled>
    )
}

const contentMarkerVariants = Object.freeze({
    NONE: 'NONE',
    ARTICLE: 'ARTICLE',
    GALLERY: 'GALLERY',
    VIDEO: 'VIDEO'
})

const contentMarkerSizeVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '1x3': '1x3',
    '2x3': '2x3',
    '1x4': '1x4',
    '1x5': '1x5',
    '1x6': '1x6'
})

ContentMarker.propTypes = {
    className: PropTypes.string,
    sizeVariant: PropTypes.oneOf([...Object.values(contentMarkerSizeVariants)]),
    variant: PropTypes.oneOf([...Object.values(contentMarkerVariants)]),
    categoryColor: PropTypes.string,
    tiny: PropTypes.bool
}

ContentMarker.defaultProps = {
    className: undefined,
    sizeVariant: contentMarkerSizeVariants['1x2'],
    variant: contentMarkerVariants.NONE,
    categoryColor: undefined,
    tiny: false
}

export { contentMarkerVariants, contentMarkerSizeVariants }

export default withErrorBoundary(ContentMarker, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoContentMarker]: ', error, componentStack)
    }
})
