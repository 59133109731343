import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import styles from './Ribbon.net.style'

const RibbonStyled = styled.div(props => ({ ...styles(props) }))

function Ribbon({ title, className, variant, iconComponent: IconComponent, ...rest }) {
    const titleHtmlProps = useInnerHtml(title)

    if ((variant === 'alpha' && !title) || (variant === 'beta' && !IconComponent)) {
        return null
    }

    return (
        <RibbonStyled className={className} variant={variant} {...rest}>
            {variant === 'alpha' ? <div className="ribbon_text" {...titleHtmlProps} /> : IconComponent}
        </RibbonStyled>
    )
}

const ribbonVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

Ribbon.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(ribbonVariants)]),
    iconComponent: PropTypes.node
}

Ribbon.defaultProps = {
    className: undefined,
    title: undefined,
    variant: ribbonVariants.ALPHA,
    iconComponent: undefined
}

export { ribbonVariants }

export default withErrorBoundary(Ribbon, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Ribbon]: ', error, componentStack)
    }
})
