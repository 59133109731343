import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
// @TODO: reconnect all mentions of this component to shared one
function ConditionalWrapper({ condition, Wrapper, children, ...rest }) {
    return condition ? <Wrapper {...rest}>{children}</Wrapper> : children
}

ConditionalWrapper.propTypes = {
    condition: PropTypes.bool.isRequired,
    Wrapper: PropTypes.elementType.isRequired
}

export default withErrorBoundary(ConditionalWrapper, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ConditionalWrapper]: ', error, componentStack)
    }
})
