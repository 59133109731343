/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const ViberNet = createSvgIcon(
    <>
        <path
            fill="#665CAC"
            className="background"
            d="M0 11.2c0-5.28 0-7.92 1.64-9.56C3.28 0 5.92 0 11.2 0h5.6c5.28 0 7.92 0 9.56 1.64C28 3.28 28 5.92 28 11.2v5.6c0 5.28 0 7.92-1.64 9.56C24.72 28 22.08 28 16.8 28h-5.6c-5.28 0-7.92 0-9.56-1.64C0 24.72 0 22.08 0 16.8v-5.6z"
        />
        <path
            fill="#fff"
            d="M21.837 16.114c.52-4.384-.25-7.15-1.641-8.403-2.244-2.138-9.824-2.454-12.509.096-1.205 1.247-1.63 3.077-1.677 5.344-.046 2.267-.101 6.513 3.856 7.665h.003l-.003 1.76s-.027.712.428.855c.52.17.756-.162 2.376-2.096 2.708.235 4.788-.303 5.025-.382.547-.183 3.641-.593 4.142-4.84zm-8.9 3.983s-1.714 2.137-2.247 2.692c-.175.18-.366.164-.363-.194 0-.235.013-2.92.013-2.92-3.355-.962-3.157-4.578-3.12-6.47.035-1.892.382-3.442 1.403-4.486 2.355-2.208 9-1.714 10.694-.123 2.07 1.835 1.333 7.019 1.337 7.195-.425 3.545-2.933 3.77-3.394 3.923-.197.066-2.026.536-4.323.383z"
        />
        <path
            fill="#fff"
            d="M13.885 9.135c-.28 0-.28.436 0 .44 2.173.016 3.962 1.53 3.981 4.308 0 .293.43.29.426-.003-.024-2.993-1.978-4.729-4.407-4.745z"
        />
        <path
            fill="#fff"
            d="M16.743 13.423c-.007.29.422.303.425.01.036-1.65-.982-3.01-2.894-3.153-.28-.02-.309.42-.03.44 1.659.126 2.532 1.258 2.499 2.703zM16.285 15.3c-.36-.208-.726-.079-.877.126l-.316.41c-.16.207-.461.18-.461.18-2.192-.58-2.779-2.874-2.779-2.874s-.026-.31.174-.477l.396-.327c.198-.157.323-.536.121-.907-.538-.973-.9-1.308-1.083-1.565-.194-.243-.485-.297-.787-.133h-.007c-.629.367-1.317 1.056-1.097 1.765.376.748 1.067 3.131 3.269 4.932 1.035.852 2.673 1.724 3.368 1.926l.006.01c.686.228 1.352-.487 1.707-1.135v-.005c.158-.314.106-.61-.125-.805-.408-.398-1.025-.838-1.51-1.121z"
        />
        <path
            fill="#fff"
            d="M14.574 11.903c.699.041 1.038.406 1.074 1.156.013.293.439.273.426-.02-.047-.98-.558-1.525-1.477-1.576-.28-.016-.306.424-.023.44z"
        />
    </>,
    'ViberNet'
)

export default ViberNet
