/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const User = createSvgIcon(
    <>
        <path d="m 16,3.76471 c -3.11887,0 -5.647057,2.528163 -5.647057,5.647066 0,3.118865 2.528187,5.647028 5.647057,5.647028 3.118869,0 5.647057,-2.528163 5.647057,-5.647028 C 21.647057,6.292873 19.118869,3.76471 16,3.76471 Z M 6.5882386,9.411776 C 6.5882386,4.213831 10.802072,0 16,0 c 5.197927,0 9.411761,4.213831 9.411761,9.411776 0,5.197907 -4.213834,9.411738 -9.411761,9.411738 -5.197928,0 -9.4117614,-4.213832 -9.4117614,-9.411738 z" />
        <path d="m 16,18.823514 c -6.237551,0 -11.2941136,5.056552 -11.2941136,11.294131 C 4.7058864,31.157241 3.8631572,32 2.8235344,32 1.7839112,32 0.94118207,31.157242 0.94118207,30.117645 0.94118207,21.800836 7.6832028,15.058804 16,15.058804 c 8.316797,0 15.058818,6.742032 15.058818,15.058841 C 31.058818,31.157242 30.216089,32 29.176465,32 28.136842,32 27.294113,31.157242 27.294113,30.117645 27.294113,23.880066 22.237551,18.823514 16,18.823514 Z" />
    </>,

    'User'
)

export default User
