import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme }) => {
    const config = {
        sm: {
            padding: toRems([0, 16, 16, 16]),
            text: {
                fontSize: toRem(14),
                lineHeight: 1.4
            },
            icon: {
                width: toRem(14),
                height: toRem(14)
            }
        },
        md: {
            padding: toRems([0, 30, 20, 40]),
            text: {
                fontSize: toRem(14),
                lineHeight: 1.6
            },
            icon: {
                width: toRem(14),
                height: toRem(14)
            }
        }
    }

    return {
        display: 'flex',
        // padding: config.sm.padding,
        // [theme.breakpoints.up('md')]: {
        //     padding: config.md.padding
        // },
        '.description': {
            '&_icon': {
                position: 'relative',
                top: toRem(5),
                width: config.sm.icon.width,
                height: config.sm.icon.height,
                [theme.breakpoints.up('md')]: {
                    width: config.md.icon.width,
                    height: config.md.icon.height
                }
            },
            '&_text': {
                p: {
                    color: theme.colors.text.placeholder,
                    fontStyle: 'italic',
                    lineHeight: config.sm.text.lineHeight,
                    fontSize: config.sm.text.fontSize,
                    [theme.breakpoints.up('md')]: {
                        lineHeight: config.md.text.lineHeight,
                        fontSize: config.md.text.fontSize
                    }
                }
            }
        }
    }
}

export default style
