import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { ConditionalWrapper } from '../../../ConditionalWrapper'
import { AuthorNetIcon, CalendarNetIcon, ClockNetIcon, Icon, PhotoNetIcon } from '../../../Icon'
import { Link } from '../../../Link'
import styles from './Item.net.style'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

function Item({ className, title, href, variant, ...rest }) {
    const getItem = () => {
        switch (variant) {
            case 'alpha':
                return (
                    <Icon
                        className="metaItem_icon"
                        id="meta_calendar_icon"
                        icon={CalendarNetIcon}
                        viewBox="0 0 11 11"
                    />
                )
            case 'beta':
                return <Icon className="metaItem_icon" id="meta_clock_icon" icon={ClockNetIcon} viewBox="0 0 11 11" />
            case 'gamma':
                return (
                    <Icon
                        className="metaItem_icon author"
                        id="meta_author_icon"
                        icon={AuthorNetIcon}
                        viewBox="0 0 11 11"
                    />
                )
            case 'delta':
                return <Icon className="metaItem_icon" id="meta_photo_icon" icon={PhotoNetIcon} viewBox="0 0 13 11" />
            default:
                return null
        }
    }

    return (
        <ItemStyled className={className} variant={variant} {...rest}>
            <ConditionalWrapper className="metaItem" condition={!!href} Wrapper={Link} href={href} title={title}>
                {title?.length > 0 && getItem()}
                <div className="metaItem_title" id="meta_title">
                    {title}
                </div>
            </ConditionalWrapper>
        </ItemStyled>
    )
}

const metaItemIconVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta'
})

Item.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(metaItemIconVariants)]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    href: PropTypes.string
}

Item.defaultProps = {
    className: undefined,
    title: undefined,
    variant: metaItemIconVariants.ALPHA,
    href: undefined
}

export { metaItemIconVariants }

export default withErrorBoundary(Item, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Item]: ', error, componentStack)
    }
})
