import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import styles from './Author.net.style'

const AuthorStyled = styled.section(props => ({ ...styles(props) }))

function ArticleAuthor({ className, label, name, image, ...rest }) {
    if (!name) {
        return null
    }

    const imageUrl = `https://dpy83rqaup3nx.cloudfront.net/${image}/original`

    return (
        <AuthorStyled className={className} {...rest}>
            {image && <img src={imageUrl} alt={name} loading="lazy" />}
            <div className="author_info">
                {label && <div className="author_info_label">{label}</div>}
                {name && <div className="author_info_name">{name}</div>}
            </div>
        </AuthorStyled>
    )
}

ArticleAuthor.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

ArticleAuthor.defaultProps = {
    className: undefined,
    name: undefined,
    label: undefined,
    image: undefined
}

export default withErrorBoundary(ArticleAuthor, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ArticleAuthor]: ', error, componentStack)
    }
})
