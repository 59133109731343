import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import styles from './Title.net.style'

const NetTitleStyled = styled.div(props => ({ ...styles(props) }))

const netTitleVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

function Title({
    className = undefined,
    titleTag: TitleTag = 'h1',
    subtitle = undefined,
    title = undefined,
    inverted = false,
    variant = netTitleVariants.ALPHA,
    fontSize = undefined,
    categoryColor = undefined,
    isZodiac = false,
    ...rest
}) {
    if (!title) {
        return null
    }

    return (
        <NetTitleStyled
            variant={variant}
            className={className}
            inverted={inverted}
            fontSize1={fontSize}
            categoryColor={categoryColor}
            isZodiac={isZodiac}
            {...rest}>
            {subtitle && variant !== 'beta' && <span className="title_subtitle">{`${subtitle} / `}</span>}
            <TitleTag className="title_title">{title}</TitleTag>
        </NetTitleStyled>
    )
}

Title.propTypes = {
    className: PropTypes.string,
    titleTag: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
    inverted: PropTypes.bool,
    variant: PropTypes.oneOf([...Object.values(netTitleVariants)]),
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categoryColor: PropTypes.string,
    isZodiac: PropTypes.bool
}

export { netTitleVariants }

export default withErrorBoundary(Title, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Title]: ', error, componentStack)
    }
})
