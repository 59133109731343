/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Hamburger = createSvgIcon(
    <>
        <path d="M 31.999999,8.0000341 H 2.6259843e-7 V 2.666701 H 31.999999 Z" />
        <path d="M 31.999999,18.6667 H 2.6259843e-7 V 13.333367 H 31.999999 Z" />
        <path d="M 2.6259843e-7,29.333366 H 31.999999 V 24.000033 H 2.6259843e-7 Z" />
    </>,
    'Hamburger'
)

export default Hamburger
