import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { useGTM, useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Image, imageRatioVariants } from '../../../Image'
import { Link } from '../../../Link'
import { Title } from '../../../Title'
import { ArticleAuthor } from '../Author'
import styles from './Special.style'

const ArticleSpecialStyled = styled.div(props => ({ ...styles(props) }))

function ArticleSpecial({
    item,
    imageWidth,
    imageHeight,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    imageVariation,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    className,
    variant,
    sizeVariant,
    gtmData,
    label,
    title,
    subtitle,
    lead,
    image,
    imageLazyLoad,
    imagePreload,
    ...rest
}) {
    const leadHtmlProps = useInnerHtml(lead || item?.lead)
    const { sendDataToGTM } = useGTM()

    const handleClick = () => {
        sendDataToGTM(gtmData)
    }

    const articleTitle = title || item?.item?.title || item?.name
    const articleSubtitle = subtitle || item?.item?.subtitle || item?.extended_attributes?.subtitle
    const articleImage = image?.id || item?.image?.id || item?.item?.image?.id
    const isLive = item?.articleType?.[0]?.title === 'Live'

    // if ((!image?.id || !item?.image?.id) && !articleTitle) {
    //     return null
    // }

    return (
        <ArticleSpecialStyled
            variant={variant}
            sizeVariant={sizeVariant}
            className={`specialCard ${isLive ? 'is_live' : ''}`}
            data-upscore-object-id={item.id}
            {...rest}>
            <Link
                href={item?.sitemap?.href || item?.item?.sitemap?.href || '/'}
                title={articleTitle}
                className="specialInner"
                onClick={handleClick}>
                <div className="specialImage">
                    {isLive && (
                        <div className="cardContent_live">
                            <span className="dot" />
                            Uživo
                        </div>
                    )}
                    <Image
                        image={articleImage}
                        updatedAt={image?.updated_at}
                        variation={
                            sizeVariant === '1X3' && variant === 'alpha'
                                ? imageRatioVariants.CUSTOM_PORTRAIT
                                : imageRatioVariants.CUSTOM_LANDSCAPE
                        }
                        width={imageWidth}
                        hideAuthor
                        height={imageHeight}
                        lazyLoad={imageLazyLoad}
                        preload={!!imagePreload}
                        useLargestInstance
                    />
                </div>
                <div className="specialOverlay" />
                {(label || articleTitle) && (
                    <div className="specialContent">
                        <div className="specialContent-inner">
                            {label && variant === 'beta' && <span className="specialContent_label">{label}</span>}
                            <Title
                                className="specialContent_title"
                                inverted
                                title={articleTitle}
                                subtitle={articleSubtitle}
                                titleTag="h3"
                                data-upscore-title=""
                            />
                            {lead && variant === 'alpha' && <p className="specialContent_lead" {...leadHtmlProps} />}
                        </div>
                        {variant === 'alpha' && (
                            // @TODO: author image figure out
                            <ArticleAuthor
                                className="specialContent_author"
                                name={item?.item?.authors?.[0]?.title}
                                label="Piše"
                                image={item?.item?.authors?.[0]?.authorImageBw}
                            />
                        )}
                    </div>
                )}
            </Link>
        </ArticleSpecialStyled>
    )
}

const articleSpecialVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

const articleSpecialSizeVariants = Object.freeze({
    '1x1': '1x1',
    '1x2': '1x2',
    '1x3': '1x3',
    '2x3': '2x3',
    '1x4': '1x4',
    '1x6': '1x6'
})

ArticleSpecial.propTypes = {
    item: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    imageWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageVariation: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({}), () => null]),
    className: PropTypes.string,
    variant: PropTypes.oneOf([...Object.values(articleSpecialVariants)]),
    sizeVariant: PropTypes.oneOf([...Object.values(articleSpecialSizeVariants)]),
    gtmData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    label: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imageLazyLoad: PropTypes.bool,
    imagePreload: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

ArticleSpecial.defaultProps = {
    imageWidth: 1400,
    imageHeight: 600,
    imageVariation: undefined,
    className: undefined,
    variant: articleSpecialVariants.ALPHA,
    sizeVariant: articleSpecialSizeVariants['1x1'],
    gtmData: undefined,
    label: undefined,
    title: undefined,
    subtitle: undefined,
    lead: undefined,
    image: undefined,
    imageLazyLoad: undefined,
    imagePreload: false
}

export { articleSpecialVariants, articleSpecialSizeVariants }

export default ArticleSpecial
