/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const NetLogoOnly = createSvgIcon(
    <>
        <path
            className="colorme"
            d="M 0 14.76923 C 0 7.80695 0 4.32581 2.1629 2.1629 C 4.32581 0 7.80695 0 14.76923 0 H 17.23077 C 24.19311 0 27.67422 0 29.83705 2.1629 C 32 4.32581 32 7.80695 32 14.76923 V 17.23077 C 32 24.19311 32 27.67422 29.83705 29.83705 C 27.67422 32 24.19311 32 17.23077 32 H 14.76923 C 7.80695 32 4.32581 32 2.1629 29.83705 C 0 27.67422 0 24.19311 0 17.23077 V 14.76923 Z"
        />
        <path
            fill="white"
            d="M 10.75825 6.49151 C 11.59137 5.65839 12.94215 5.65839 13.77526 6.49151 L 21.77526 14.49157 C 22.60837 15.32468 22.60837 16.67532 21.77526 17.50855 L 13.77526 25.50855 C 12.94215 26.34166 11.59137 26.34166 10.75825 25.50855 C 9.92513 24.67532 9.92513 23.32468 10.75825 22.49157 L 17.24972 16 L 10.75825 9.5085 C 9.92513 8.67538 9.92513 7.32463 10.75825 6.49151 Z"
        />
    </>,
    'NetLogoOnly'
)

export default NetLogoOnly
