/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'

const Ugc = createSvgIcon(
    <>
        <defs />
        <path
            fill="#080808"
            d="M1.2 20.3c.9 1.8 3 2.7 4.8 2.2l1.8 3.7c.2.4.7.6 1 .4L12 25c.4-.2.6-.7.4-1.1l-1.8-3.6c7.1-3.2 12.7-1.7 14.6-1.7a.8.8 0 00.9-1.2L17.9 1a.8.8 0 00-1.5-.1C15.3 2.3 13 8 5.6 11.7l-3.2 1.5c-2.1 1-3 3.5-2 5.6l.8 1.5zm7.7 4.4L7.5 22 9 21l1.4 2.9-1.5.7zM7 13c3-1.7 5.8-4 8-6.7l5 10.5c-3.4 0-6.9.7-10.1 2.2l-3-6zM17 3l6.8 14-2-.2L16 4.7 17 3zM3.1 14.7l2.3-1 2.9 6-2.3 1c-1.2.6-2.7.1-3.3-1.1L1.9 18c-.6-1.3 0-2.8 1.2-3.4zM22 6.5c.4.2 1 0 1-.5l1.2-3.2a.8.8 0 10-1.6-.5l-1.1 3.2c-.2.4 0 .9.5 1zM24.3 9c.3.3.9.3 1.2 0l1.8-1.8.5 1a.8.8 0 001.4.3L31.9 6a.8.8 0 00-1.1-1.2l-2 1.8-.5-1a.8.8 0 00-1.3-.3l-2.7 2.6c-.3.3-.3.9 0 1.2zM28.3 10.8a.8.8 0 00-1.2-.5l-2 1a.8.8 0 00.7 1.6l1.3-.7.6 1.6c.1.5.7.7 1.2.4l2.3-1.3a.8.8 0 00-.9-1.5l-1.4.9-.6-1.5z"
        />
    </>,
    'Ugc'
)

export default Ugc
