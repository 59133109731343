import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useInnerHtml } from '@hmn/rtl-web-core/hooks'

import { Icon, QuoteIcon } from '../../../Icon'
import styles from './Blockquote.net.style'

const BlockquoteStyled = styled.div(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Blockquote({ className, content, ...rest }) {
    const contentHtmlProps = useInnerHtml(content)
    return (
        <BlockquoteStyled {...rest}>
            <Icon className="blockquote_icon" icon={QuoteIcon} viewBox="0 0 48 48" fill="#FFFFFF" />
            <div className="blockquote_content" {...contentHtmlProps} />
        </BlockquoteStyled>
    )
}

Blockquote.propTypes = {
    className: PropTypes.string,
    content: PropTypes.string
}

Blockquote.defaultProps = {
    className: undefined,
    content: undefined
}

export default withErrorBoundary(Blockquote, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Blockquote]: ', error, componentStack)
    }
})
