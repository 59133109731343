import styled from '@emotion/styled'

const style = ({ theme }) => ({
    boxSizing: 'content-box',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    width: '100%',
    maxWidth: '320px',
    maxHeight: '100px',
    transform: 'translateX(-50%)',

    [theme.breakpoints.up('md')]: {
        maxWidth: '350px'
    },

    '.inpicture__ad': {
        zIndex: 1,
        margin: 0
    },

    // this is close button
    '& > span': {
        display: 'none',
        position: 'absolute',
        top: '-20px',
        right: '-3px',
        width: '40px',
        height: '20px',
        lineHeight: '17px',
        textAlign: 'center',
        zIndex: 2,
        backgroundColor: '#fff',
        rotate: '180deg',
        borderRadius: '0px 0px 3px 3px', // be careful with this because we are rotating it
        cursor: 'pointer',

        '& svg': {
            width: '20px',
            height: '20px',
            fill: '#000'
        }
    },

    '&.inpicture:has([id$="__container__"] > iframe, [id$="_ad_container"] > iframe)': {
        // style ad container when ad is generated
        border: '3px solid #fff',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',

        // show close button when ad is generated
        '& > span': {
            display: 'block'
        }
    }
})

const InpictureStyled = styled.div(style)
export default InpictureStyled
