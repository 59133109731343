/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dynamic from 'next/dynamic'

// eslint-disable-next-line import/extensions
import commonElements from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.elements'
import { EmbedArticle, EmbedImage } from '@hmn/rtl-web-core/components/HtmlContent/HtmlContent.style'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useEmbedById } from '@hmn/rtl-web-core/context/embeds'
import { namespace as docbookNamespace } from '@hmn/rtl-web-core/hooks'

import {
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants
} from '../Article/components/Card/Card.variants'
import { ConditionalWrapper } from '../ConditionalWrapper'
import { imageRatioVariants } from '../Image'
import { embeds } from './HtmlContent.embeds'

const Image = dynamic(() => import('../Image/Image.component'))
const ArticleBlockquote = dynamic(() => import('../Article/components/Blockquote/Blockquote.net.component'))
const ArticleCard = dynamic(() => import('../Article/components/Card/Card.component'))
const ArticleImageDescription = dynamic(() =>
    import('../Article/components/ImageDescription/ImageDescription.net.component')
)
const ArticlePoll = dynamic(() => import('../Article/components/Poll/Poll.component').then(m => m.default), {
    ssr: false
})
const VideoContent = dynamic(() => import('../Video/components/Content/Content.component'))

const elements = commonElements({
    ArticleCard,
    ArticleBlockquote,
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants,
    ArticleImageDescription,
    ArticlePoll,
    embeds,
    imageRatioVariants,
    VideoContent
})

elements.set(`${docbookNamespace}/gallery`, block => {
    // console.log('[GALLERY]', block)

    const { namespace, entity, id, align, column, width, height, ...attrs } = block.attributes
    const gallery = useEmbedById(id)
    const gtmData = {
        eventCategory: 'Clanak',
        eventAction: 'In body preporuka',
        eventLabel: 'Galerija'
    }
    return (
        <EmbedArticle {...attrs} align={align} blockWidth={width} blockHeight={height}>
            {gallery ? (
                <>
                    <Media greaterThan="sm" scaleDown>
                        <ArticleCard
                            className="embedArticle"
                            variant={articleCardVariants.DELTA}
                            imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                            item={gallery}
                            imageWidth={350}
                            imageHeight={200}
                            imageSizesMd={40}
                            imageSizesSm={25}
                            href={gallery?.sitemap?.href}
                            embedded
                            inverted
                            rightAlign
                            sizeVariant={articleCardSizeVariants['1x2']}
                            contentMarkerVariant={articleCardContentMarkerVariants.GALLERY}
                            gtmData={gtmData}
                            imageLazyload
                        />
                    </Media>
                    <Media lessThan="md" scaleDown>
                        <ArticleCard
                            className="embedArticle"
                            variant={articleCardVariants.DELTA}
                            imageVariation={imageRatioVariants.CUSTOM_SQUARE}
                            item={gallery}
                            imageWidth={115}
                            imageHeight={115}
                            imageSizesMd={40}
                            imageSizesSm={25}
                            href={gallery?.sitemap?.href}
                            embedded
                            inverted
                            rightAlign
                            sizeVariant={articleCardSizeVariants['1x6']}
                            contentMarkerVariant={articleCardContentMarkerVariants.GALLERY}
                            gtmData={gtmData}
                            imageLazyload
                        />
                    </Media>
                </>
            ) : null}
        </EmbedArticle>
    )
})

elements.set(`${docbookNamespace}/article`, ({ attributes }) => {
    const { namespace, entity, id, align, width, height, ...attrs } = attributes

    const article = useEmbedById(id)
    const hasVideoEmbed = article?.body?.indexOf('<hmn:video') !== -1
    const gtmData = {
        eventCategory: 'Clanak',
        eventAction: 'In body preporuka',
        eventLabel: 'Link'
    }
    if (!article) {
        return null
    }
    // @TODO: check this when re-doing preloading for content
    // On amp the div will sometimes contain width and height attributes because of some deconstruction problem
    // Maybe use completely alternate component?
    return (
        <EmbedArticle {...attrs} align={align} blockWidth={width} blockHeight={height}>
            <Media greaterThan="sm" scaleDown>
                <ArticleCard
                    variant={articleCardVariants.DELTA}
                    imageVariation={imageRatioVariants.CUSTOM_LANDSCAPE}
                    sizeVariant={hasVideoEmbed ? articleCardSizeVariants['1x2'] : articleCardSizeVariants['1x6']}
                    item={article}
                    contentMarkerVariant={
                        hasVideoEmbed ? articleCardContentMarkerVariants.VIDEO : article?.type.toUpperCase()
                    }
                    href={article.sitemap?.href}
                    embedded={hasVideoEmbed}
                    imageWidth135
                    imageSizesMd={40}
                    imageSizesSm={25}
                    gtmData={gtmData}
                    imageLazyload
                    titleTagComponent="h3"
                />
            </Media>

            <Media lessThan="md" scaleDown>
                <ArticleCard
                    className="embedArticle"
                    variant={articleCardVariants.DELTA}
                    imageVariation={imageRatioVariants.CUSTOM_SQUARE}
                    item={article}
                    imageWidth={115}
                    imageHeight={115}
                    href={article?.sitemap?.href}
                    imageSizesMd={40}
                    imageSizesSm={25}
                    // embedded={hasVideoEmbed}
                    embedded
                    // inverted
                    // rightAlign
                    sizeVariant={articleCardSizeVariants['1x6']}
                    gtmData={gtmData}
                    imageLazyload
                    titleTagComponent="h3"
                />
            </Media>
        </EmbedArticle>
    )
})

elements.set(`${docbookNamespace}/image`, ({ attributes }) => {
    // console.log('[IMAGE]', block)

    const { namespace, entity, id, width, height, caption, href, ...attrs } = attributes
    const target = !href?.startsWith(process.env.NEXT_PUBLIC_APP_ROOT_NET) ? '_blank' : null

    const image = useEmbedById(id)

    if (!image) {
        return null
    }
    return (
        <EmbedImage {...attrs}>
            <ConditionalWrapper condition={!!href} Wrapper="a" href={href} target={target} title={caption}>
                <Image
                    // useLargestInstance
                    image={image}
                    width={width || 1000}
                    height={height || 400}
                    sizesMd={80}
                    sizesSm={95}
                    variation={imageRatioVariants.CUSTOM_ORIGINAL}
                    originalAspectRatio={image?.original_aspect_ratio}
                    imageLazyload
                />
            </ConditionalWrapper>
            <ArticleImageDescription description={image?.description} />
        </EmbedImage>
    )
})

export { elements }
