/* eslint-disable max-len */
import React from 'react'

import createSvgIcon from '@hmn/rtl-web-core/components/Icon/utils/createSvgIcon'
// "0 0 11 18"
const ArrowLeftAlt = createSvgIcon(
    <path d="M9 2L2 9L9 16" stroke="#080808" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />,

    'ArrowLeftAlt'
)

export default ArrowLeftAlt
